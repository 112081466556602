<template>
  <div data-testId="deviceList">
    <div class="flex flex-col gap-10">
      <div class="flex flex-col gap-4">
        <Checkbox
          v-if="isRoomWithoutSrtAndRadiator"
          v-model:checked="currentRoom.hasRadiator"
          label="Kein Heizkörper im Raum"
          :value="currentRoom.hasRadiator"
          :is-inverted="true"
          test-id="hasRadiator"
          @update:checked="updateHasRadiator"
        />
        <BaseInformation
          v-if="infoText"
          :status="infoStatus"
          :information-text="infoText"
        />
      </div>
      <DeviceTypeTable
        v-for="(value, key) in noMountingDevices"
        :key="key"
        :devices="value"
        :device-type="key"
        :on-edit="editDevice"
        :on-delete="openDeleteDeviceConfirmationModal"
        :on-exchange="exchangeDevice"
      />
      <DeviceTypeTable
        v-for="(value, key) in deviceTypesWithDevices"
        :key="key"
        :devices="value"
        :device-type="key"
        :on-edit="editDevice"
        :on-delete="openDeleteDeviceConfirmationModal"
        :on-exchange="exchangeDevice"
      />

      <DeviceTypeTable
        v-for="(value, key) in testDeviceTypesWithDevices"
        :key="key"
        :devices="value"
        :device-type="key"
        :on-edit="editTestDevice"
        :on-delete="openDeleteDeviceConfirmationModal"
        :on-exchange="exchangeDevice"
      />

      <AdapterTypeTable
        v-for="(value, key) in inspectionPointsWithAdapters"
        :key="key"
        :device-type="key"
        :on-edit="editAdapterForm"
        :on-delete="openDeleteDeviceConfirmationModal"
        :inspection-point="value"
      />
    </div>
    <div class="fixed bottom-8 right-10 z-10 flex justify-between">
      <ScrollButton
        :button-text="addDeviceButtonLabel"
        :button-icon="addDeviceButtonIcon"
        data-testid="addDeviceButton"
        @on-click="addDevice"
      />
    </div>
    <BasePrompt
      question="Sind Sie sicher, dass Sie dieses Gerät löschen möchten?"
      :open="isDeleteConfirmationModalOpen"
      :cancel="closeDeletionModal"
      title="Gerät löschen"
      :close="closeDeletionModal"
      :proceed="deleteDevice"
    />
    <BasePrompt
      :open="isAccessiblePromptOpen"
      :close="toggleAccessiblePrompt"
      :cancel="cancelIsAccessibleChange"
      :proceed="changeToIsAccessible"
      title="Dieser Raum ist als nicht zugänglich definiert."
      question="Kein Zutritt"
      cancel-text="Weiterhin kein Zutritt"
      proceed-text="Raum zugänglich"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import DeviceTypeTable from './DeviceListFormComponents/DeviceTypeTable.vue';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { FormAction, useFormStore } from '@/store/form/formStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { Form } from '@/models/Form';
import { entityTypes } from '@/enums/generic';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';
import { useRoomForm } from '@/composables/useRoomForm';
import { Room } from '@/models';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { DeviceClasses } from '@/models/devices/Device';
import { useOrderStore } from '@/store/order/orderStore';
import { useInspectionPointStore } from '@/store/entities/inspectionPointStore';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { useInspectionDeviceStore } from '@/store/entities/inspectionDeviceStore';
import { InspectionDeviceClasses } from '@/models/inspectionPoint/InspectionDevice';
import { DeviceTypeEnum } from '@/enums/device';
import AdapterTypeTable from '@/components/Forms/DeviceListFormComponents/AdapterTypeTable.vue';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useRoomStore } from '@/store/entities/roomStore';
import Checkbox from '@/components/UI/Checkbox/Checkbox.vue';

const props = defineProps({
  parentId: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['update-entity']);

const deviceStore = useDeviceStore();
const installationPointStore = useInstallationPointStore();
const entitiesStore = useEntitiesStore();
const formStore = useFormStore();

const isDeleteConfirmationModalOpen = ref(false);
const isAccessiblePromptOpen = ref(false);
const toBeDeletedInstallationPoint = ref<InstallationPoint | undefined>(
  undefined
);

const currentRoom = computed(() => {
  return useRoomStore().getRoomById(props.parentId);
});

const inspectionPointsWithAdapters = computed(() => {
  const inspectionPoints =
    useInspectionPointStore().getInspectionPointsByParentId(props.parentId);
  return inspectionPoints.filter(
    (ip) =>
      !ip.activeTestDeviceId &&
      (ip?.accessories?.length > 0 ||
        ip?.inspectionData?.noAdapterNeeded ||
        ip.failedInstallationAttempt)
  );
});

const testDeviceTypesWithDevices = computed(() => {
  const devices: {
    [key: string]: InspectionDeviceClasses[];
  } = {};

  const testInspectionPoints =
    useInspectionPointStore().getInspectionPointsByParentId(props.parentId);
  const testDevicesInRoom = useInspectionPointStore().getTestDevicesByRoomId(
    props.parentId
  );
  testDevicesInRoom.forEach((device) => {
    const inspectionPoint = testInspectionPoints?.find(
      (ip) =>
        ip.activeTestDeviceId === device.id &&
        ip.supportedDeviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY
    );

    const radioCheckResult = inspectionPoint?.inspectionData?.radioCheckResult;

    if (device.deviceType === DeviceTypeEnum.LO_RA_WAN_GATEWAY) {
      devices[radioCheckResult] ??= [];
      devices[radioCheckResult].push(device);
    } else {
      devices[device.deviceType] ??= [];
      devices[device.deviceType].push(device);
    }
  });
  return devices;
});

const deviceTypesWithDevices = computed(() => {
  const devices: {
    [key: string]: DeviceClasses[];
  } = {};

  const devicesInRoom = deviceStore.getAllDevicesInRoom(props.parentId);
  devicesInRoom.forEach((device) => {
    if (!devices[device.deviceType]) {
      devices[device.deviceType] = [];
    }
    devices[device.deviceType].push(device);
  });
  return devices;
});

let localRoom: Room | undefined = undefined;

const { room, handleIsAccessibleChange, handleSubmit } = useRoomForm(
  localRoom,
  props.parentId,
  emit
);

const editAdapterForm = (inspectionPoint: InspectionPoint) => {
  formStore.editDevice(inspectionPoint, props.parentId);
};

const addDevice = () => {
  localRoom = useEntitiesStore().getEntityById(props.parentId);
  if (!localRoom) return;
  localRoom.isAccessible ? openDeviceForm() : toggleAccessiblePrompt();
};
const openDeviceForm = () => {
  useFormStore().setFormAction(FormAction.CREATE);
  let entityType: string = entityTypes.installationPoint;
  if (useOrderStore().isOnSiteInspection()) {
    entityType = entityTypes.inspectionPoint;
  }
  useFormStore().createEntity(
    new Form().setParentId(props.parentId).setName(entityType)
  );
};

const exchangeDevice = (installationPoint: InstallationPoint) => {
  if (installationPoint.activeDeviceId) {
    formStore.replaceDevice(installationPoint);
    return;
  }
  formStore.mountDevice(installationPoint);
};

const addDeviceButtonLabel = computed(() => {
  return useOrderStore().isOnSiteInspection()
    ? 'Geräte erfassen'
    : 'Geräte hinzufügen';
});

const addDeviceButtonIcon = computed(() => {
  return useOrderStore().isOnSiteInspection() ? 'pipe-valve' : 'cellphone';
});

const noMountingDevices = computed(() => {
  const devices: {
    [key: string]: InstallationPoint[];
  } = {};
  const installationPoints =
    installationPointStore.getInstallationPointsByRoomId(props.parentId);

  const installationPointsWithDevices = installationPoints.filter(
    (ip) => ip.supportedDeviceType !== undefined
  );
  installationPointsWithDevices.forEach((ip) => {
    if (!ip.activeDeviceId && ip.failedInstallationAttempt) {
      if (!devices['UNMOUNTED']) {
        devices['UNMOUNTED'] = [];
      }
      devices['UNMOUNTED'].push(ip);
    }
  });
  return devices;
});

function editDevice(installationPoint: InstallationPoint) {
  formStore.editDevice(installationPoint, props.parentId);
}

function editTestDevice(inspectionPoint: InspectionPoint) {
  formStore.editDevice(inspectionPoint, props.parentId);
}

function openDeleteDeviceConfirmationModal(
  installationPoint: InstallationPoint
) {
  toBeDeletedInstallationPoint.value = installationPoint;
  isDeleteConfirmationModalOpen.value = true;
}

function deleteDevice() {
  if (!toBeDeletedInstallationPoint.value) return;

  const relatedDevices = useDeviceStore().getDevicesByInstallationPointId(
    toBeDeletedInstallationPoint.value.id
  );

  const inspectionDevices =
    useInspectionDeviceStore().getDevicesByInspectionPointId(
      toBeDeletedInstallationPoint.value.id
    );

  for (const device of relatedDevices) {
    entitiesStore.deleteEntity(device);
  }

  for (const device of inspectionDevices) {
    entitiesStore.deleteEntity(device);
  }

  if (toBeDeletedInstallationPoint.value.radiatorId) {
    const radiator = useEntitiesStore().getEntityById(
      toBeDeletedInstallationPoint.value.radiatorId
    );
    if (radiator.isPlaceholderRadiator()) {
      entitiesStore.deleteEntity(radiator);
    }
  }

  entitiesStore.deleteEntity(toBeDeletedInstallationPoint.value);
  closeDeletionModal();
}

function closeDeletionModal() {
  isDeleteConfirmationModalOpen.value = false;
  toBeDeletedInstallationPoint.value = undefined;
}

const toggleAccessiblePrompt = () => {
  isAccessiblePromptOpen.value = !isAccessiblePromptOpen.value;
};

const cancelIsAccessibleChange = () => {
  toggleAccessiblePrompt();
  openDeviceForm();
};

const changeToIsAccessible = () => {
  if (!localRoom) return;
  room.value = localRoom;
  handleIsAccessibleChange(true);
  handleSubmit();
  toggleAccessiblePrompt();
  openDeviceForm();
};

const radiators = computed(() => {
  return useRadiatorStore().getAllRadiatorsInRoom(props.parentId);
});

const activeAndFailedSrtDevices = computed(() => {
  return useRoomStore().getActiveAndFailedSrtDevices(currentRoom.value.id);
});

const infoText = computed(() => {
  if (
    currentRoom.value.hasRadiator &&
    radiators.value.length === 0 &&
    activeAndFailedSrtDevices.value.length === 0
  ) {
    return 'Für diesen Raum müssen HK Geräte erfasst werden.';
  }
  if (currentRoom.value.hasRadiator === false) {
    return 'In diesem Raum sind keine Heizkörper vorhanden.';
  }
  if (
    Object.values(deviceTypesWithDevices.value).length === 0 &&
    Object.values(noMountingDevices.value).length === 0
  ) {
    return 'In diesem Raum sind noch keine Geräte installiert.';
  }
  return undefined;
});

const infoStatus = computed(() => {
  return currentRoom.value.hasRadiator &&
    radiators.value.length === 0 &&
    activeAndFailedSrtDevices.value.length === 0
    ? 'error'
    : 'info';
});

const updateHasRadiator = () => {
  useEntitiesStore().saveEntity(currentRoom.value);
};

const hasSrtDevices = computed(() => {
  return useDeviceStore().getSrtDevicesInRoom(props.parentId).length > 0;
});

const isRoomWithoutSrtAndRadiator = computed(() => {
  return currentRoom.value.hasRadiator !== undefined && !hasSrtDevices.value;
});
</script>
