<template>
  <div class="flex flex-col gap-3">
    <BaseInformation
      class="mb-[-1px]"
      status="warning"
      information-text="Ein abgeschlossener Auftrag kann nicht nachträglich bearbeitet werden. Bist Du sicher, dass alle notwendigen Angaben korrekt und vollständig erfasst wurden?"
    />
    <BaseSection heading="Besondere Hinweise zum Auftrag">
      <BaseTextarea
        v-model:input-text="order.installerNote"
        label="Notizen"
        test-id="textarea_note"
      />
    </BaseSection>
    <BaseSection heading="Fotos">
      <ImageCapture
        ref="imageCaptureRef"
        :object-id="order.id"
        :object-type="ImageObjectType.ORDER"
        :capture-image-information="true"
        a
      />
    </BaseSection>
  </div>
</template>
<script setup lang="ts">
import { onBeforeMount, ref, Ref } from 'vue';
import { ImageObjectType } from '@/models/Image';
import ImageCapture, {
  ImageCaptureRef,
} from '@/components/Forms/DeviceFormComponents/ImageCapture.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { Order } from '@/models/Order';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import { useRouter } from 'vue-router';
import { useFormChangeTrackerPlugin } from '@/composables/useFormChangeTracker';
import { useCloseOrderButton } from '@/composables/useCloseOrderButton';

const props = defineProps<{
  node: Order;
  parentId?: string;
  payload?: any;
}>();

const emit = defineEmits(['update-entity']);
const router = useRouter();
const imageCaptureRef: Ref<ImageCaptureRef | null> = ref(null);

const order = ref<Order>(props.node);

const { finishOrder } = useCloseOrderButton();
const handleSubmit = async () => {
  try {
    if (imageCaptureRef.value) {
      await imageCaptureRef.value.saveImage();
    }
  } catch (error) {
    console.error('An error occurred during image upload:', error);
  }
  await finishOrder();
};

onBeforeMount(() => {
  useFormChangeTrackerPlugin(order);
});

defineExpose({
  isFormValid: true,
  handleSubmit,
  saveImage: () => imageCaptureRef.value?.saveImage(),
});
</script>
