<template>
  <BaseToast v-if="imageUpload.isImageUploadActive">
    <div>
      <div>Speichern...</div>
      <div v-if="imageUpload.totalNumberOfImages > 0">
        [ {{ imageUpload.currentUploadedImage }} /
        {{ imageUpload.totalNumberOfImages }}
        Bildern]
      </div>
      <div class="mt-2 h-2 w-full overflow-hidden rounded-full bg-white">
        <div class="progress left-right h-full w-full bg-black"></div>
      </div>
    </div>
  </BaseToast>
</template>

<script lang="ts" setup>
import BaseToast from '@/components/UI/Toast/BaseToast.vue';
import { computed } from 'vue';
import { useImagesStore } from '@/store/images/imageStore';

const imageUpload = computed(() => {
  return useImagesStore().imageUpload;
});
</script>

<style>
.progress {
  animation: progress 1s infinite linear;
}

.left-right {
  transform-origin: 0% 50%;
}
@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
</style>
