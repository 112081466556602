import { OrderStatus } from '@/models/types/OrderStatus';
import { ContactPerson } from '@/models/types/ContactPerson';
import { OrderChanges } from '@/models/OrderChanges';
import { OrderDetail } from '@/models/OrderDetail';
import { Address } from '@/models/types/Address';
import { Type } from 'class-transformer';
import 'reflect-metadata';

export class Order {
  public id: string;
  public orderId: string;
  public businessEntityId: string;
  public billingUnitReference: string;
  public workTypeCode?: OrderType;
  public locationReference?: string;
  public propertyId: string;
  public appointment: OrderAppointment;
  public articles?: OrderArticles;
  public serviceGroups?: Array<OrderServiceGroup>;
  public notes?: string;
  public contactPerson?: ContactPerson;
  public description?: string;
  public roomGroupAppointments?: Array<string>;
  public status: OrderStatus;
  @Type(() => OrderChanges)
  public changes?: OrderChanges;
  public primaryAddress: Address;
  public additionalAddresses?: Array<Address>;
  public installerNote: string;
  public installerId: string;
  @Type(() => OrderDetail)
  public detail?: OrderDetail;

  constructor() {
    this.id = '';
    this.orderId = '';
    this.businessEntityId = '';
    this.billingUnitReference = '';
    this.workTypeCode = undefined;
    this.locationReference = '';
    this.propertyId = '';
    this.appointment = {
      start: '',
      end: '',
    };
    this.description = '';
    this.articles = {
      items: [],
      note: undefined,
    };
    this.serviceGroups = undefined;
    this.roomGroupAppointments = [];
    this.status = OrderStatus.OPEN;
    this.contactPerson = undefined;
    this.notes = '';
    this.primaryAddress = new Address();
    this.additionalAddresses = [];
    this.changes = new OrderChanges();
    this.installerNote = '';
    this.installerId = '';
    this.detail = new OrderDetail();
  }
}

export enum OrderType {
  ON_SITE_INSPECTION = 'BE',
}
