<template>
  <div class="prose prose-neutral relative dark:prose-invert">
    <form class="flex flex-col gap-8" @submit.prevent>
      <BaseSection heading="Raum Informationen">
        <div v-if="isFirstRoomOfRoomGroup">
          <div
            class="flex items-center border-gray-400 bg-light-1 px-4 py-3 pb-2.5 text-sm font-bold text-dark-0 dark:border-dark-3 dark:bg-dark-2 dark:text-white dark:focus:border-primary-2"
          >
            Das Ändern des Zimmertyps im Treppenhaus ist nicht erlaubt.
          </div>
        </div>

        <BaseDropdown
          v-else
          v-model:selection="room.usageType"
          :cols="2"
          :options-list="roomTypeList"
          dropdown-text="Nutzungsart wählen"
          :validation="v$.room.usageType"
          label="Nutzungsart des Raumes"
        />

        <div class="md:flex md:gap-4">
          <BaseFormInput
            v-model="room.number"
            class="md:basis-1/5"
            label="Raum Nr."
            test-id="input_roomNumber"
            :optional="true"
          />
          <BaseFormInput
            v-model="room.description"
            :optional="true"
            class="md:basis-4/5"
            label="Raumbeschreibung"
            :max-length="128"
            test-id="input_roomDescription"
          />
        </div>
      </BaseSection>
      <BaseSection heading="Stockwerk des Raumes anpassen" :required="false">
        <FloorLevelSelector v-model:value="room.floorLevel" :room="room" />
      </BaseSection>
      <BaseSection heading="Besonderheiten" :required="false">
        <Checkbox
          v-model:checked="room.hasRadiator"
          label="Kein Heizkörper"
          :is-inverted="true"
          test-id="hasRadiator_checkbox"
        />
      </BaseSection>
      <BaseSection heading="Weitere Angaben" :required="false">
        <div class="mt-6">
          <BaseToggle
            v-model:is-checked="room.hasHighCeiling"
            label="Hohe Decken"
            test-id="toggle_hasHighCeiling"
          />
          <BaseToggle
            v-model:is-checked="room.isDIN14676"
            label="Ausstattung nach DIN 14676"
            test-id="toggle_isDIN14676"
          />
          <BaseToggle
            v-model:is-checked="room.isAccessible"
            label="Kein Zutritt"
            :is-inverted="true"
            test-id="toggle_isAccessible"
            @is-toggled="handleIsAccessibleChange($event)"
          />
          <div v-if="!room.isAccessible" class="mt-3 gap-3 md:flex">
            <BaseDropdown
              v-model:selection="room.reasonNotAccessible"
              :options-list="roomNotAccessibleReasonsList"
              :validation="v$.room.reasonNotAccessible"
              dropdown-text="Grund wählen"
              label="Grund"
              class="md:basis-4/5"
              :numbered="true"
            />
            <BaseDateTime
              v-model:date="room.dateNotAccessible"
              :validation="v$.room.dateNotAccessible"
              class="md:basis-1/5"
            />
          </div>
        </div>
        <BaseTextarea
          v-model:input-text="room.note"
          label="Notizen"
          test-id="textarea_note"
          :validation="v$.room.note"
        />
      </BaseSection>
    </form>
  </div>
</template>
<script setup lang="ts">
import BaseDropdown from '@/components/UI/Dropdown/BaseDropdown.vue';
import { onBeforeMount } from 'vue';
import { Room } from '@/models';
import { roomTypeList, roomNotAccessibleReasonsList } from '@/enums/room';
import FloorLevelSelector from '@/components/Forms/RoomFormComponents/FloorLevelSelector.vue';
import BaseFormInput from '@/components/UI/Input/BaseFormInput.vue';
import BaseTextarea from '@/components/UI/Note/BaseTextarea.vue';
import BaseToggle from '@/components/UI/Switch/BaseToggle.vue';
import { useValidation } from '@/composables/useValidation';
import { useRoomForm } from '@/composables/useRoomForm';
import BaseDateTime from '@/components/UI/Input/BaseDateTime.vue';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import { useFormChangeTrackerPlugin } from '@/composables/useFormChangeTracker';
import Checkbox from '@/components/UI/Checkbox/Checkbox.vue';

const props = defineProps<{
  node?: Room;
  parentId: string;
}>();

const emit = defineEmits(['update-entity']);

const {
  room,
  handleSubmit,
  handleIsAccessibleChange,
  initializeRoomForm,
  isFirstRoomOfRoomGroup,
} = useRoomForm(props.node, props.parentId, emit);

onBeforeMount(() => {
  initializeRoomForm();
  useFormChangeTrackerPlugin(room);
});

const { v$, isFormValid } = useValidation({ room });

defineExpose({
  handleSubmit,
  isFormValid,
});
</script>
