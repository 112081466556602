<template>
  <div class="inline-flex items-center" :class="{ 'brightness-75': disabled }">
    <label class="relative flex cursor-pointer items-center rounded-full">
      <input
        id="checkbox"
        v-model="checkboxValue"
        type="checkbox"
        :value="value"
        :disabled="disabled"
        class="before:content[''] border-blue-gray-200 before:bg-blue-gray-500 peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border bg-transparent transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-primary-2 checked:bg-primary-2 checked:before:bg-primary-2 hover:before:opacity-10 focus:border-primary-2"
        :data-testId="`checkbox_${testId}_state`"
      />
      <span class="no-selection ml-3" :data-testId="`checkbox_${testId}`">
        {{ label }}
      </span>
    </label>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  checked: {
    type: Boolean,
    default: undefined,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Boolean],
    default: undefined,
  },
  testId: {
    type: String,
    default: '',
  },
  isInverted: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:checked', 'isChecked']);

const checkboxValue = computed({
  get() {
    if (props.checked === undefined) {
      return false;
    }
    if (props.isInverted) {
      return !props.checked;
    }
    return props.checked;
  },
  set(value) {
    let isChecked = value;
    if (props.isInverted) {
      isChecked = !isChecked;
    }
    emit('update:checked', isChecked);
    emit('isChecked', isChecked);
  },
});
</script>
