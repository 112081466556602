import { Room, Roomgroup } from '@/models';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { entityTypes } from '@/enums/generic';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Store } from '@/store/pinia-class-component';
import { useRoomStore } from '@/store/entities/roomStore';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { useOrderStore } from '@/store/order/orderStore';

export function useRoomGroupStore() {
  return new RoomGroupStore();
}

@Store
export class RoomGroupStore extends miniEntityStore {
  private _roomGroups: Map<string, Roomgroup>;
  private _activeRoomGroupId = '';
  private _maxRoomGroupNumber = 0;
  private _roomGroupsFinishedClicked: string[];

  constructor() {
    super();
    this._roomGroups = new Map();
    this._activeRoomGroupId = '';
    this._maxRoomGroupNumber = 0;
    this._roomGroupsFinishedClicked = [];
  }

  get roomGroups(): Map<string, Roomgroup> {
    return this._roomGroups;
  }

  get activeRoomGroupId(): string {
    return this._activeRoomGroupId;
  }

  isRoomGroupFinishedClicked(roomGroupId: string) {
    return this._roomGroupsFinishedClicked.includes(roomGroupId);
  }

  addToRoomGroupsFinishedClicked(roomGroupId: string) {
    this._roomGroupsFinishedClicked.push(roomGroupId);
  }

  removeFromRoomGroupsFinishedClicked(roomGroupId: string) {
    this._roomGroupsFinishedClicked = this._roomGroupsFinishedClicked.filter(
      (id) => id !== roomGroupId
    );
  }

  isRoomGroupFinished(roomGroupId: string) {
    if (!roomGroupId) return;
    const rooms = this.getStores().roomStore.getRoomsByRoomGroupId(roomGroupId);

    const unfinishedRooms = rooms.filter((room) => {
      return !this.getStores().roomStore.isFinished(room.id);
    });

    return unfinishedRooms.length === 0;
  }

  removeRoomGroupProgress() {
    if (!this.getCurrentRoomGroup) return;
    this.getStores().orderStore.removeRoomgroupProgress(
      this.getCurrentRoomGroup
    );
  }

  resetState() {
    this._roomGroups = new Map();
    this._activeRoomGroupId = '';
    this._maxRoomGroupNumber = 0;
  }

  getStores() {
    const roomStore = useRoomStore();
    const entitiesStore = useEntitiesStore();
    const orderStore = useOrderStore();

    return {
      roomStore,
      entitiesStore,
      orderStore,
    };
  }

  get getCurrentRoomGroup() {
    return this._roomGroups.get(this._activeRoomGroupId);
  }

  get uniqueRoomGroupNumber() {
    const roomGroupsNumber = new Map();
    this._roomGroups.forEach((roomGroup) => {
      if (roomGroup.number) {
        roomGroupsNumber.set(+roomGroup.number, roomGroup.id);
      }
    });
    return roomGroupsNumber;
  }

  get uniqueDeviceReference() {
    const deviceReference = new Map();

    this._roomGroups.forEach((roomGroup) => {
      if (roomGroup.deviceReference) {
        deviceReference.set(roomGroup.deviceReference, roomGroup.id);
      }
    });
    return deviceReference;
  }

  get nextRoomGroupNumber(): number {
    return Math.ceil((this._maxRoomGroupNumber + 10) / 10) * 10;
  }

  isDeviceReferenceDuplicated(roomGroup: Roomgroup) {
    const deviceReferenceId = this.uniqueDeviceReference.get(
      roomGroup.deviceReference
    );
    return !!(deviceReferenceId && deviceReferenceId !== roomGroup.id);
  }

  isRoomGroupNumberDuplicated(roomGroup: Roomgroup) {
    const roomGroupNumberId = this.uniqueRoomGroupNumber.get(roomGroup.number);
    return !!(roomGroupNumberId && roomGroupNumberId !== roomGroup.id);
  }

  addEntities(entities: Roomgroup[]): void {
    this.resetState();
    this._roomGroups = new Map(
      entities.map((entity) => {
        this.updateRoomGroupNumbersOnAdd(entity);
        return [entity.id, entity] as [string, Roomgroup];
      })
    );
  }

  addEntity(newRoomGroup: Roomgroup): void {
    this.updateRoomGroupNumbersOnAdd(newRoomGroup);
    this._roomGroups.set(newRoomGroup.id, newRoomGroup);
  }

  deleteEntity(roomGroup: Roomgroup) {
    if (!roomGroup.id) {
      throw new Error(`Cannot delete roomgroup without a valid id`);
    }

    if (roomGroup.type !== entityTypes.roomgroup) {
      throw new Error(
        `Cannot delete an entity that is not a roomgroup, entity: ${roomGroup.type}`
      );
    }

    if (
      this.getStores().entitiesStore.getAllEntitiesByParentId(roomGroup.id)
        .length > 1
    ) {
      throw new Error(
        `Cannot delete a roomgroup with more than 1 room, children: ${
          this.getStores().entitiesStore.getAllEntitiesByParentId(roomGroup.id)
            .length
        }`
      );
    }

    const firstRoomOfRoomGroup =
      this.getStores().roomStore.getFirstRoomOfRoomgroup(roomGroup.id);
    if (firstRoomOfRoomGroup) {
      this.getStores().entitiesStore.deleteEntity(firstRoomOfRoomGroup);
    }
    this.updateRoomGroupNumbersOnDelete(roomGroup);
    this._roomGroups.delete(roomGroup.id);
  }

  getRoomGroupsByBuildingId(buildingId: string) {
    return getAllEntitiesByCriteria(
      this._roomGroups,
      (node: Roomgroup) => node.buildingId === buildingId
    );
  }

  getNewRoomgroupOrdinal(parentId: string) {
    const entity = this.getStores().entitiesStore.getEntityById(parentId);

    if (entity.type === entityTypes.building) {
      return 1;
    }

    const children = this.getStores().roomStore.getChildrenOfRoom(
      entity.id
    ) as Array<Room>;

    const siblings = [];

    children.forEach((child) => {
      if (
        entity &&
        entity.roomGroupId &&
        entity.floorLevel.type === child.floorLevel.type
      ) {
        siblings.push(child);
      }
    });

    return siblings.length + 1;
  }

  isRoomgroupEmpty(roomgroup: Roomgroup) {
    const firstRoomOfRoomGroup =
      this.getStores().roomStore.getFirstRoomOfRoomgroup(roomgroup.id);
    if (!firstRoomOfRoomGroup) {
      throw new Error('First room of roomgroup is undefined');
    } else {
      const childrenOfFirstRoom =
        this.getStores().entitiesStore.getAllEntitiesByParentId(
          firstRoomOfRoomGroup.id
        );

      return childrenOfFirstRoom.length === 0;
    }
  }

  swapRoomGroup(roomGroupSource: Roomgroup, roomGroupTarget: Roomgroup) {
    if (roomGroupSource.type !== entityTypes.roomgroup) {
      throw new Error('Invoked SWAP_Roomgroup: entity is not a roomgroup');
    }

    const entitiesStore = useEntitiesStore();

    const sourceOrdinal = roomGroupSource.ordinal;

    roomGroupSource.ordinal = roomGroupTarget.ordinal;
    roomGroupTarget.ordinal = sourceOrdinal;

    entitiesStore.saveEntity(roomGroupSource);
    entitiesStore.saveEntity(roomGroupTarget);
  }

  setActiveMapRoomGroup(roomgroupId: string) {
    if (!this._roomGroups.get(roomgroupId)) {
      throw new Error(`Can only set a roomgroup to be active in the map view`);
    }
    this._activeRoomGroupId = roomgroupId;
  }

  private updateRoomGroupNumbersOnAdd(roomGroup: Roomgroup): void {
    if (roomGroup.number === undefined) return;
    if (roomGroup.number > this._maxRoomGroupNumber) {
      this._maxRoomGroupNumber = roomGroup.number;
    }
  }

  private updateRoomGroupNumbersOnDelete(roomGroup: Roomgroup): void {
    if (roomGroup.number === undefined) return;
    if (roomGroup.number === this._maxRoomGroupNumber) {
      this._maxRoomGroupNumber = Math.max(
        0,
        ...this.uniqueRoomGroupNumber.keys()
      );
    }
  }
}
