import { getValidator } from '@/validation/validator';
import { computed, Ref } from 'vue';
import { Validation } from '@vuelidate/core';
import { useFormStore } from '@/store/form/formStore';

/**
 * Returns validation ref
 *
 * @param ruleRecord - object of key as rules and value as entity
 * @param options - object of additional options for the rules
 */
export function useValidation(
  ruleRecord: Record<string, unknown>,
  options?: any
): {
  v$: Ref<Validation>;
  isFormValid: Ref<boolean>;
} {
  const v$: Ref<Validation> = getValidator(ruleRecord, options);
  if (useFormStore().currentForm.name) {
    useFormStore().setValidation(v$.value);
  }
  const isFormValid: Ref<boolean> = computed(() => {
    return !v$.value.$invalid;
  });

  return {
    v$,
    isFormValid,
  };
}
