<template>
  <BaseButton
    class="border-transparent"
    :class="[isDisabled | isMuted ? disabledClasses : color]"
    :disabled="isDisabled"
    :type="props.type"
  >
    <em v-if="icon" :class="['mdi', icon]"></em>
    {{ label }}
  </BaseButton>
</template>
<script setup lang="ts">
import BaseButton from '@/components/UI/Button/BaseButton.vue';
import { computed } from 'vue';

const props = defineProps<{
  label: string;
  icon?: string;
  type?: string;
  isDisabled?: boolean;
  isMuted?: boolean;
}>();

const disabledClasses = computed(() => {
  return 'bg-light-disabled-background text-light-disabled-text dark:bg-dark-disabled-background dark:text-dark-disabled-text';
});

const color = computed(() => {
  if (props.type === 'error') {
    return 'bg-light-error text-white hover:bg-dark-error dark:bg-dark-error dark:hover:bg-light-error';
  }
  return 'bg-primary-2 text-white hover:bg-primary-1 dark:bg-primary-1 dark:hover:bg-primary-0';
});
</script>
