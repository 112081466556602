import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { default as envConfig } from '@/config/runtimeConfig';
import { AuthMessage, UserAuth } from '@/models/auth/UserAuth';
import { User } from '@/models/auth/User';
import { Pinia, Store } from '@/store/pinia-class-component';
import * as Sentry from '@sentry/browser';
import { WebEvents } from '@/native/plugins/WebEvents';
import { useOrderStore } from '@/store/order/orderStore';

type AuthUser = CognitoUser & {
  attributes: { preferred_username: string; sub: string; picture: string };
};

const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: 'eu-central-1',
      userPoolId: envConfig.cognitoUserPoolId,
      userPoolWebClientId: envConfig.cognitoClientID,
      authenticationFlowType: 'USER_SRP_AUTH',
    },
  });
  Auth.configure();
  return Auth;
};

export function useUserStore() {
  return new UserStore();
}

@Store
class UserStore extends Pinia {
  private _auth: typeof Auth;
  private _user: AuthUser | undefined;
  private _userSettings: {
    showValveHint: boolean;
    showGatewayLocationHint: boolean;
  };

  constructor() {
    super();
    this._auth = configureAmplify();
    this._user = undefined;
    this._userSettings = {
      showValveHint: true,
      showGatewayLocationHint: true,
    };
  }

  get getJwtToken() {
    return this._user?.getSignInUserSession()?.getAccessToken().getJwtToken();
  }

  get getFullname() {
    if (!this._user) {
      return;
    }
    return this._user.attributes.preferred_username || this._user.getUsername();
  }

  get userAttributes() {
    return this._user?.attributes;
  }

  get isValveHintVisible() {
    return this._userSettings.showValveHint;
  }

  async isUserDeveloper() {
    const userGroups = await this.getUserGroups();
    // Check if user is in the developer group partially match
    return userGroups.some((group) => group.includes('developer'));
  }

  async getUserGroups(): Promise<string[]> {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().payload['cognito:groups'];
  }

  get isGatewayLocationHintVisible() {
    return this._userSettings.showGatewayLocationHint;
  }

  fetchCurrentUser() {
    return this._auth
      .currentAuthenticatedUser()
      .then((data) => {
        return data;
      })
      .catch(() => {
        return;
      });
  }

  setUser(newUser: AuthUser) {
    this._user = newUser;
    Sentry.setUser({ username: newUser.getUsername() });
    WebEvents.onUserAuthenticated({ username: newUser.getUsername() });
  }

  login(credentials: User) {
    return this._auth
      .signIn(credentials.username, credentials.password)
      .then((response) => {
        this.clearUserOrders();
        this.setUser(response);
        return new UserAuth()
          .setAuthSuccess(true)
          .setChallengeName(response.challengeName);
      })
      .catch((error) => {
        return this.generateAuthentication(false, error.message);
      });
  }

  forgetPassword(credentials: User) {
    return this._auth
      .forgotPassword(credentials.username)
      .then(() => {
        return this.generateAuthentication(true, AuthMessage.EMAIL_SENT);
      })
      .catch((error) => {
        return this.generateAuthentication(false, error.message);
      });
  }

  setNewPassword(credentials: User) {
    return this._auth
      .completeNewPassword(this._user, credentials.newPassword)
      .then(() => {
        return this.generateAuthentication(true, AuthMessage.PASSWORD_CHANGED);
      })
      .catch((error) => {
        return this.generateAuthentication(false, error.message);
      });
  }

  changePasswordWithResetCode(credentials: User) {
    return this._auth
      .forgotPasswordSubmit(
        credentials.username,
        credentials.resetCode,
        credentials.newPassword
      )
      .then(() => {
        return this.generateAuthentication(true, AuthMessage.PASSWORD_CHANGED);
      })
      .catch((error) => {
        return this.generateAuthentication(false, error.message);
      });
  }

  logout() {
    return this._auth
      .currentSession()
      .then(() => {
        this.clearUserOrders();
        this._auth.signOut().then(() => {
          this._user = undefined;
        });
      })
      .catch((error) => {
        console.debug(error);
      });
  }

  clearUserOrders() {
    useOrderStore().clearOrders();
  }

  generateAuthentication(success: boolean, message: AuthMessage) {
    return new UserAuth().setAuthSuccess(success).setMessage(message);
  }

  hideValveHint() {
    this._userSettings.showValveHint = false;
  }

  hideGatewayLocationHint() {
    this._userSettings.showGatewayLocationHint = false;
  }

  allowGatewayLocationHint() {
    this._userSettings.showGatewayLocationHint = true;
  }
}
