<template>
  <div class="flex flex-row p-1">
    <div
      class="flex h-[15.5rem] w-[13.5rem] flex-col rounded-xl border bg-zinc-200 shadow dark:bg-dark-3"
      :class="borderClass"
    >
      <div class="flex flex-row justify-between pl-3 pr-1 pt-2 text-2xl">
        <div class="relative flex flex-row">
          <em
            :class="[
              { 'mdi-sofa': roomGroup.usageType === 'LIVING' },
              { 'mdi-briefcase': roomGroup.usageType === 'BUSINESS' },
              { 'mdi-home-roof': roomGroup.usageType === 'GENERAL' },
              { 'mdi-stairs': isStaircase },
              'mdi ',
            ]"
          />
          <div
            class="ml-1 self-center break-all text-sm text-gray-500 dark:text-gray-400"
          >
            {{ roomGroup.businessType }}
            <br />
          </div>
          <em
            v-if="isRoomGroupComplete"
            data-testId="roomGroupCompleteIcon"
            class="mdi mdi-check-bold absolute left-3 top-3 text-primary-2"
          ></em>
        </div>
        <BasePrompt
          :open="isDeletePromptOpen"
          :close="toggleDeletePrompt"
          :cancel="toggleDeletePrompt"
          :proceed="deleteRoomgroup"
          title="Raumgruppe löschen"
          question="Sind Sie sicher, dass Sie diese Raumgruppe löschen möchten?"
        />
        <BasePrompt
          :open="isDeleteDevicesPromptOpen"
          :close="toggleDeleteDevicesPrompt"
          :cancel="toggleDeleteDevicesPrompt"
          :proceed="removeDevicesFromRoomGroup"
          title="Testgeräte entfernen"
          question="Sind Sie sicher, dass Sie alle Testgeräte entfernen möchten?"
        />
        <BasePrompt
          :open="isAccessiblePromptOpen"
          :close="toggleAccessiblePrompt"
          :cancel="cancelIsAccessibleChange"
          :proceed="changeToIsAccessible"
          title="Diese Raumgruppe ist als nicht zugänglich definiert."
          cancel-text="Weiterhin kein Zutritt"
          proceed-text="Raumgruppe zugänglich"
        >
          <BaseChip
            text="Kein Zutritt"
            type="warning"
            class="text-xs"
            icon="mdi-cancel"
            data-testId="notAccessibleChip"
          />
        </BasePrompt>
        <div class="flex">
          <OptionsDropdown
            v-if="!isStaircase"
            width="w-[15rem]"
            icon="mdi-dots-vertical"
          >
            <li
              data-testid="roomGroupOptionEdit"
              @click="editRoomGroup(roomGroup)"
            >
              <em class="mdi mdi-pencil mr-2" />Bearbeiten
            </li>
            <li
              v-if="isRoomGroupComplete"
              data-testid="roomGroupOptionFinish"
              @click="toggleRoomGroupProgress"
            >
              <em
                class="mdi mdi-check mr-2"
                :class="{ 'mdi-close': isRoomGroupComplete }"
              />{{ roomGroupProgressLabel }}
            </li>
            <li
              v-if="isOnSiteInspection"
              data-testid="roomGroupOptionRemoveDevices"
              @click="toggleDeleteDevicesPrompt"
            >
              <em class="mdi mdi-trash-can mr-2" /> Testgeräte entfernen
            </li>
            <li
              data-testid="roomGroupOptionDelete"
              :class="[
                {
                  'cursor-not-allowed text-light-disabled-text hover:bg-transparent dark:text-dark-disabled-text':
                    !isRoomgroupDeletable,
                },
              ]"
              @click="isRoomgroupDeletable && toggleDeletePrompt()"
            >
              <em class="mdi mdi-delete mr-2" />Löschen
            </li>
          </OptionsDropdown>
        </div>
      </div>
      <div class="mt-2 flex-grow cursor-pointer" @click="goToRoomGroup">
        <div class="px-3 text-gray-500 dark:text-gray-400">
          <span v-if="roomGroup.customerAssignmentReference"
            >{{ roomGroup.customerAssignmentReference }} / </span
          >{{ roomGroup.number }}
          <br />
        </div>
        <div
          class="line-clamp-2 break-words px-3 text-xl font-bold dark:text-slate-100"
        >
          {{ roomGroupLabel }}
        </div>
      </div>
      <div class="flex gap-2 px-2 py-3">
        <BaseChip
          v-if="!roomGroup.isAccessible"
          text="Kein Zutritt"
          type="warning"
          class="text-xs"
          icon="mdi-cancel"
          :outlined="useLayoutStore().isDarkMode()"
        />
        <BaseChip
          v-if="roomGroup.isVacant"
          text="Leerstand"
          type="info"
          class="text-xs"
          :outlined="useLayoutStore().isDarkMode()"
        />
      </div>
      <DeviceListDropdown :room-group="roomGroup" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref } from 'vue';
import { Notification, Roomgroup } from '@/models';
import DeviceListDropdown from '@/components/BuildingOverview/DeviceListDropdown.vue';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { useRouter } from 'vue-router';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import BaseChip from '@/components/UI/Chip/BaseChip.vue';
import useStores from '@/composables/useStores';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useRoomGroupProgress } from '@/composables/useRoomGroupProgress';
import { useRoomGroupForm } from '@/composables/useRoomgroupForm';
import { useOrderStore } from '@/store/order/orderStore';
import { useInspectionPointStore } from '@/store/entities/inspectionPointStore';
import { InspectionPoint } from '@/models/inspectionPoint/InspectionPoint';
import { DeviceTypeEnum } from '@/enums/device';
import { FormAction } from '@/store/form/formStore';
const router = useRouter();

const { roomStore, roomGroupStore, formStore, layoutStore } = useStores();

const isDeletePromptOpen = ref(false);
const isAccessiblePromptOpen = ref(false);
const isDeleteDevicesPromptOpen = ref(false);
const isDragging = inject('isDragging', ref(false));
const toggleDeletePrompt = () => {
  isDeletePromptOpen.value = !isDeletePromptOpen.value;
};
const toggleAccessiblePrompt = () => {
  isAccessiblePromptOpen.value = !isAccessiblePromptOpen.value;
};
const toggleDeleteDevicesPrompt = () => {
  isDeleteDevicesPromptOpen.value = !isDeleteDevicesPromptOpen.value;
};

const isOnSiteInspection = computed(() => {
  return useOrderStore().isOnSiteInspection();
});

const props = defineProps<{
  roomGroup: Roomgroup;
  isStaircase?: boolean;
  isLast: boolean;
}>();

const emit = defineEmits(['update-entities']);

const { roomgroup, handleIsAccessibleChange, initializeRoomGroupForm } =
  useRoomGroupForm(props.roomGroup, props.roomGroup.buildingId);

const borderClass = computed(() => {
  return useOrderStore().unfinishedRoomGroups().get(props.roomGroup.id)
    ? 'border-2 border-light-error dark:border-dark-error'
    : 'border-zinc-300 dark:border-dark-4';
});

onMounted(() => {
  initializeRoomGroupForm();
});

const {
  completeRoomGroup,
  toggleRoomGroupProgress,
  isRoomGroupComplete,
  roomGroupProgressLabel,
} = useRoomGroupProgress(props.roomGroup);

const roomGroupLabel = computed(() => {
  if (props.isStaircase) {
    return 'Hausflur / Treppenhaus';
  }
  if (props.roomGroup.usageType === 'GENERAL') {
    return 'Allgemeine Nutzung';
  }
  return props.roomGroup.doorbellName;
});

const isRoomgroupDeletable = computed(() => {
  if (props.isStaircase) {
    return;
  }
  return roomGroupStore.isRoomgroupEmpty(props.roomGroup);
});

const deleteRoomgroup = () => {
  isDeletePromptOpen.value = false;
  useEntitiesStore().deleteEntity(
    props.roomGroup,
    new Notification(
      'Nutzeinheit wurde gelöscht',
      'Nutzeinheit wurde entfernt!',
      'info',
      3000
    )
  );
};

const removeDevicesFromRoomGroup = () => {
  const inspectionPoints =
    useInspectionPointStore().getInspectionPointsByRoomGroupId(
      props.roomGroup.id
    );

  const activeInspectionPoints = inspectionPoints.filter(
    (inspectionPoint: InspectionPoint) =>
      inspectionPoint.activeTestDeviceId &&
      inspectionPoint.supportedDeviceType ===
        DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT
  );

  activeInspectionPoints.forEach((inspectionPoint: InspectionPoint) => {
    const device = inspectionPoint.getActiveDevice();
    useEntitiesStore().deleteEntity(device);
    inspectionPoint.unsetActiveDevice();
    useEntitiesStore().saveEntity(inspectionPoint);
  });

  isDeleteDevicesPromptOpen.value = false;
};

const cancelIsAccessibleChange = () => {
  toggleAccessiblePrompt();
  enterRoomGroup();
};
const changeToIsAccessible = () => {
  handleIsAccessibleChange(true);
  useEntitiesStore().saveEntity(roomgroup.value);
  toggleAccessiblePrompt();
  enterRoomGroup();
};
const enterRoomGroup = () => {
  roomGroupStore.setActiveMapRoomGroup(props.roomGroup.id);
  router.push(`/detailansicht`);
};
const goToRoomGroup = () => {
  if (isDragging.value) {
    return;
  }
  return props.roomGroup.isAccessible
    ? enterRoomGroup()
    : toggleAccessiblePrompt();
};
const editRoomGroup = (roomGroup: Roomgroup) => {
  formStore.editEntity(roomGroup);
};
</script>
