export const hashString = (input: string) => {
  return fnv1aHash(input).toString(16);
};

/* Hashing algorithm based on the FNV Method
 * NOT for cryptographic use, only to generate unique hashes for strings
 */
function fnv1aHash(input: string): number {
  const FNV_PRIME = 16777619;
  let hash = 2166136261;

  for (let i = 0; i < input.length; i++) {
    hash ^= input.charCodeAt(i);
    hash = (hash * FNV_PRIME) >>> 0;
  }

  return hash;
}
