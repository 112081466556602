<template>
  <button
    :class="[
      'text-md flex flex-row items-center justify-center rounded-2xl  p-3.5 shadow transition-all',
      isDisabled
        ? 'cursor-not-allowed bg-light-disabled-background text-light-disabled-text dark:bg-dark-disabled-background dark:text-dark-disabled-text'
        : 'cursor-pointer bg-primary-2 text-white hover:bg-primary-1 dark:bg-primary-1 dark:hover:bg-primary-0',
      {
        'w-56': !isScrolled,
        'w-14 ': isScrolled,
      },
    ]"
    :disabled="isDisabled"
    @click="onClick"
  >
    <span class="flex flex-col items-center justify-center text-xl">
      <em v-if="buttonIcon" :class="`mdi mdi-${buttonIcon}`" />
    </span>

    <span
      v-if="!isScrolled"
      class="flex-col items-center justify-center overflow-hidden whitespace-nowrap pl-2"
    >
      {{ showAlternativeButtonText ? alternativeButtonText : buttonText }}
    </span>
  </button>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';

interface Props {
  buttonText?: string;
  alternativeButtonText?: string;
  buttonIcon?: string;
  showAlternativeButtonText?: boolean;
  isDisabled?: boolean;
}

const emit = defineEmits(['on-click']);

const props = withDefaults(defineProps<Props>(), {
  buttonText: undefined,
  alternativeButtonText: undefined,
  buttonIcon: undefined,
});

const isScrolled = inject('isScrolled', ref(false));

const onClick = () => {
  emit('on-click');
};
</script>
