import { useLayoutStore } from '@/store/layout/layoutStore';
import { Notification } from '@/models';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { useOrderStore } from '@/store/order/orderStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useImagesStore } from '@/store/images/imageStore';

export function useCloseOrderButton() {
  const persistenceStore = usePersistenceStore();
  const imagesStore = useImagesStore();
  const orderStore = useOrderStore();
  const notificationStore = useNotificationStore();
  const router = useRouter();

  const isOnline = computed(() => useLayoutStore().isOnline);
  const modalOpen = ref(false);

  const startSaveProcess = async (): Promise<boolean> => {
    if (!isOnline.value) {
      notificationStore.addNotification(
        new Notification(
          'Stell sicher, dass du mit dem Internet verbunden bist.',
          'Du bist offline!',
          'error'
        )
      );
      return false;
    }

    persistenceStore.setIsSaving(true);

    try {
      await persistenceStore.saveData();
      await imagesStore.uploadImages();

      notificationStore.addNotification(
        new Notification(
          'Daten wurden erfolgreich gespeichert.',
          'Speichern erfolgreich',
          'info',
          8000
        )
      );
      return true;
    } catch (error) {
      console.error('Error saving data', error);
      notificationStore.addNotification(
        new Notification(
          'Es gab einen Fehler beim Speichern der Daten.',
          'Speichern fehlgeschlagen',
          'error',
          10000
        )
      );
      return false;
    } finally {
      persistenceStore.setIsSaving(false);
    }
  };
  const toggleModal = () => {
    modalOpen.value = !modalOpen.value;
    if (modalOpen.value) {
      orderStore.setOrderConfirmationDialogDisplayed(true);
    }
  };

  const finishOrder = async () => {
    const layoutStore = useLayoutStore();
    layoutStore.setLoadingState(true);

    const saveSuccessful = await startSaveProcess();

    if (!saveSuccessful) {
      console.error('Save process failed. Aborting finishOrder.');
      layoutStore.resolveLoaderState(
        'Speichern fehlgeschlagen. Auftrag konnte nicht abgeschlossen werden.',
        false
      );
      setTimeout(() => {
        layoutStore.setLoadingState(false);
        persistenceStore.setIsSaving(false);
        toggleModal();
      }, 1500);
      return;
    }

    try {
      await orderStore.finishOrder();

      layoutStore.resolveLoaderState(
        'Auftrag erfolgreich abgeschlossen...',
        true
      );
      useLayoutStore().closeFormDialog();
      setTimeout(() => {
        layoutStore.setLoadingState(false);
        router.push({ name: 'Home' });
      }, 1500);
    } catch (error) {
      console.error('Error finishing order:', error);
      layoutStore.resolveLoaderState(
        'Fehler beim Abschluss des Auftrags...',
        false
      );
      setTimeout(() => {
        layoutStore.setLoadingState(false);
        persistenceStore.setIsSaving(false);
        toggleModal();
      }, 1500);
    }
  };

  return {
    isOnline,
    modalOpen,
    startSaveProcess,
    toggleModal,
    finishOrder,
  };
}
