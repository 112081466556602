import { Building, Notification, Room } from '@/models';
import { computed, Ref, ref } from 'vue';
import useStores from '@/composables/useStores';
import { Form } from '@/models/Form';
import { FormAction } from '@/store/form/formStore';

export function useBuildingCard(building?: Ref<Building>) {
  const {
    roomGroupStore,
    roomStore,
    entitiesStore,
    formStore,
    layoutStore,
    buildingStore,
  } = useStores();

  const isBuildingDeletable = computed(() => {
    if (!building) return false;
    const allRoomgroups = roomGroupStore.getRoomGroupsByBuildingId(
      building.value.id
    );
    const allRooms = roomStore.getRoomsByRoomGroupId(allRoomgroups[0].id);
    const firstRoom = allRooms[0] as Room;

    const allDevices = entitiesStore.getAllEntitiesByParentId(firstRoom.id);

    return (
      allRoomgroups.length <= 1 &&
      allRooms.length <= 1 &&
      allDevices.length <= 0
    );
  });

  const buildingDeletionPrompt = ref(false);
  const onDeleteBuilding = () => {
    deleteBuilding();
  };

  const deleteBuilding = () => {
    if (!building) return false;
    entitiesStore.deleteEntity(
      building.value,
      new Notification('Gebäude wurde gelöscht.', 'Gebäude gelöscht')
    );
  };

  const openDeleteBuildingDialog = () => {
    buildingDeletionPrompt.value = true;
  };

  const editBuilding = (building: Building) => {
    formStore.setFormAction(FormAction.EDIT);
    changeActiveBuilding(building);
    formStore.editEntity(building, building?.businessEntityId);
    layoutStore.openFormDialog();
  };

  const changeActiveBuilding = (building: Building) => {
    buildingStore.setBuilding(building);
  };

  const addBuilding = () => {
    if (entitiesStore.activeBusinessEntityId) {
      formStore.createEntity(
        new Form()
          .setName('building')
          .setParentId(entitiesStore.activeBusinessEntityId)
      );
    }
  };

  const isActiveBuilding = (building: Building) => {
    const activeBuilding = buildingStore.getCurrentBuilding();
    if (activeBuilding) {
      return building.id === activeBuilding.id;
    }
    return false;
  };

  return {
    isActiveBuilding,
    openDeleteBuildingDialog,
    deleteBuilding,
    buildingDeletionPrompt,
    isBuildingDeletable,
    changeActiveBuilding,
    onDeleteBuilding,
    editBuilding,
    addBuilding,
  };
}
