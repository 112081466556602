<template>
  <div class="pb-5 pt-3">
    <div class="relative">
      <input
        :id="uniqueId"
        ref="input"
        :type="type"
        :disabled="disabled"
        :value="modelValue"
        :maxlength="maxLength"
        :spellcheck="autoCorrect"
        :autocomplete="autoCorrect ? 'on' : 'off'"
        :autocorrect="autoCorrect ? 'on' : 'off'"
        class="peer w-full appearance-none rounded-t-lg border-0 border-b-2 border-gray-400 px-2.5 pb-2.5 pt-5 focus:border-primary-2 focus:outline-none focus:ring-0 dark:border-dark-3 dark:focus:border-primary-2"
        :class="{
          'bg-light-1 text-gray-900 dark:bg-dark-2 dark:text-white': !disabled,
          'dark:bg-dark-disabled-background dark:text-gray-300': disabled,
          'dark:focus-border-red-500 border-red-600 focus:border-red-600 dark:border-red-500 dark:focus:border-red-600':
            isError,
          'dark:focus-border-yellow-500 border-yellow-600 focus:border-yellow-600 dark:border-yellow-500 dark:focus:border-yellow-600':
            isWarning,
        }"
        placeholder=" "
        :pattern="pattern"
        :inputmode="inputmode"
        :data-testid="testId"
        @input="updateInput"
      />
      <label
        :for="uniqueId"
        class="z-9 absolute left-2.5 top-3 origin-[0] -translate-y-4 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-primary-2 dark:text-gray-400 peer-focus:dark:text-primary-2"
        :class="{
          'text-red-600 peer-focus:text-red-500 dark:text-red-500 peer-focus:dark:text-red-500':
            isError,
          'text-yellow-600 peer-focus:text-yellow-500 dark:text-yellow-500 peer-focus:dark:text-yellow-500':
            isWarning,
        }"
        >{{ label }}
        <span v-if="isOptional" class="ml-1 text-xs">(optional)</span>
      </label>
      <div
        v-if="slots.trailing"
        class="absolute inset-y-0 right-2 flex cursor-pointer items-center justify-center hover:text-primary-2 focus:outline-none"
      >
        <slot name="trailing" class=""></slot>
      </div>

      <div
        v-if="unit"
        class="absolute inset-y-0 right-2 flex items-center justify-center"
      >
        {{ unit }}
      </div>

      <div
        v-if="isError"
        class="absolute mt-0 text-xs text-red-600 dark:text-red-500"
      >
        <template v-for="error in validation.$silentErrors" :key="error">
          {{ error.$message }}</template
        >
      </div>
      <div
        v-if="isWarning"
        data-testId="inputWarning"
        class="absolute mt-0 text-xs text-yellow-600 dark:text-yellow-500"
      >
        <em class="mdi mdi-alert pr-1"></em>
        {{ warning }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, getCurrentInstance, HTMLAttributes, PropType } from 'vue';
import { useSlots } from 'vue';

const slots = useSlots();
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String as PropType<'text' | 'number' | 'date' | 'datetime-local'>,
    default: 'text',
  },
  pattern: {
    type: String,
    default: null,
  },
  inputmode: {
    type: String as PropType<HTMLAttributes['inputmode']>,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  validation: {
    type: Object,
    default: undefined,
  },
  optional: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 500,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    default: '',
  },
  warning: {
    type: String,
    default: undefined,
  },
  unit: {
    type: String,
    default: undefined,
  },
  autoCorrect: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const defaultValue = {
  text: '',
  number: null,
};
const updateInput = (event: Event) => {
  const element = event.target as HTMLInputElement;
  if (element.value === '' && typeof defaultValue[props.type] !== 'undefined') {
    emit('update:modelValue', defaultValue[props.type]);
    return;
  }
  emit('update:modelValue', element.value.trim());
};

const isOptional = computed(() => {
  const propsList = getCurrentInstance()?.props;
  if (propsList && Object.keys(propsList).includes('optional')) {
    return props.optional;
  }
  return !isRequired.value;
});

const uniqueId = computed(() => {
  return Math.random().toString(36).substr(2, 9);
});

const isRequired = computed(() => {
  if (props.validation) {
    return !props.validation.required?.$params?.optional;
  }
  if (props.disabled) {
    return true;
  }
  return true;
});
const isError = computed(() => {
  return props.validation?.$errors?.length > 0 && props.validation?.$dirty;
});

const isWarning = computed(() => {
  return props.warning !== undefined && !isError.value;
});
</script>
