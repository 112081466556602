import { Entity } from '@/models/types/Entity';
import { DifferenceChange } from 'microdiff';
import { hashString } from '@/utils/cryptoUtils';

export class OrderChanges {
  roomGroupProgress: Array<string>;

  _hasChanges: boolean;
  transactions: { [key: string]: EntityTransaction };
  unsavedTransactionCount: number;
  lastSaved?: number;
  lastSavedHash: string;
  currentHash: string;

  constructor() {
    this.roomGroupProgress = [];
    this._hasChanges = false;
    this.transactions = {};
    this.unsavedTransactionCount = 0;
    this.lastSaved = undefined;
    this.lastSavedHash = '';
    this.currentHash = '';
  }

  get hasChanges() {
    console.warn(
      "The 'hasChanges' property is not used current but reserved for future use."
    );
    return this._hasChanges;
  }

  onSaveOrderChanges() {
    this.lastSaved = Math.floor(new Date().getTime() / 1000);
    this.lastSavedHash = this.currentHash;
  }

  get savedToday() {
    if (!this.lastSaved) return false;
    const date = new Date(this.lastSaved * 1000);
    const today = new Date();
    return date.toDateString() === today.toDateString();
  }

  get lastSavedTime() {
    if (!this.lastSaved) return '';
    const date = new Date(this.lastSaved * 1000);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  async onOrderChangesUpdate() {
    this.currentHash = await this.hashTransactions(this.transactions);
  }

  async hashTransactions(transactions: { [key: string]: EntityTransaction }) {
    const jsonString = JSON.stringify(transactions);
    return hashString(jsonString);
  }

  addUnsavedTransaction() {
    this.unsavedTransactionCount++;
  }

  resetUnsavedTransactionCount() {
    this.unsavedTransactionCount = 0;
  }

  addRoomGroupProgress(roomGroupId: string) {
    if (this.roomGroupProgress.includes(roomGroupId)) return;
    this.roomGroupProgress.push(roomGroupId);
  }

  removeRoomGroupProgress(roomGroupId: string) {
    this.roomGroupProgress = this.roomGroupProgress.filter(
      (roomGroupProgress) => roomGroupProgress !== roomGroupId
    );
  }

  hasRoomGroupProgress(roomGroupId: string) {
    return this.roomGroupProgress.includes(roomGroupId);
  }

  upsertTransaction(
    entity: Entity,
    type: EntityTransactionType,
    diff: DifferenceChange[] = [],
    originalEntity?: Entity
  ) {
    const newDiff = diff.map(
      (item) =>
        new TransactionDiff(item.oldValue, item.value, item.path, item.type)
    );

    const timestamp = new Date().toISOString();

    this.transactions[entity.id] = new EntityTransaction(
      entity,
      entity.type,
      entity.id,
      type,
      timestamp,
      newDiff,
      originalEntity
    );
  }
}

export class TransactionDiff {
  oldValue: any;
  newValue: any;
  path: string;
  type: string;

  constructor(oldValue: any, newValue: any, path: any[], type: string) {
    this.oldValue =
      oldValue instanceof Object ? JSON.stringify(oldValue) : oldValue;
    this.newValue =
      newValue instanceof Object ? JSON.stringify(newValue) : newValue;
    this.path = path?.join('.');
    this.type = type;
  }
}

export enum TransactionDiffType {
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
  REMOVE = 'REMOVE',
}

export class EntityTransaction {
  originalEntity?: Entity;
  entity: Entity;
  entityType: string;
  entityId: string;
  type: EntityTransactionType;
  timestamp: string;
  diff: any;

  constructor(
    entity: Entity,
    entityType: string,
    entityId: string,
    type: EntityTransactionType,
    timestamp: string,
    diff?: any,
    originalEntity?: Entity
  ) {
    this.entity = entity;
    this.entityType = entityType;
    this.entityId = entityId;
    this.type = type;
    this.diff = diff;
    this.timestamp = timestamp ?? new Date().toISOString();
    this.originalEntity = originalEntity;
  }
}

export enum EntityTransactionType {
  DELETE_ENTITY = 'DELETE_ENTITY',
  UPDATE_ENTITY = 'UPDATE_ENTITY',
  CREATE_ENTITY = 'CREATE_ENTITY',
}
