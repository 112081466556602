import { Order, OrderType } from '@/models/Order';
import { OrderStatus } from '@/models/types/OrderStatus';
import {
  fetchAllAssignedOrders,
  putOrderDetails,
  saveOrderStatus,
} from '@/api/order/OrderManagement';
import { Pinia, Store } from '@/store/pinia-class-component';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Roomgroup } from '@/models';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import {
  EntityTransaction,
  EntityTransactionType,
  OrderChanges,
  TransactionDiff,
} from '@/models/OrderChanges';
import { Entity } from '@/models/types/Entity';
import { generateDiff } from '@/utils/diff/diff';
import { DifferenceChange } from 'microdiff';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useImagesStore } from '@/store/images/imageStore';
import { inject } from 'vue';
import { ANALYTICS_PROVIDER } from '@/plugins/bxAnalytics';
import { useUserStore } from '@/store/user/userStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';

export function useOrderStore() {
  return new OrderStore();
}

@Store
export class OrderStore extends Pinia {
  private _orders: Array<Order>;
  private _activeOrder: Order | undefined;
  private _orderType?: OrderType;
  private _isAutoSaving: boolean;
  private _analytics: any;
  private _orderConfirmationDialogDisplayed = false;

  constructor() {
    super();
    this._orders = [];
    this._activeOrder = undefined;
    this._orderType = undefined;
    this._isAutoSaving = false;
    this._analytics = inject(ANALYTICS_PROVIDER);
  }

  get isAutoSaving() {
    return this._isAutoSaving;
  }

  setIsAutoSaving(value: boolean) {
    this._isAutoSaving = value;
  }

  get orderType() {
    return this._orderType;
  }

  get hasUnsavedOrderChanges() {
    const orderChanges = this.getOrderChanges();
    return orderChanges?.currentHash !== orderChanges?.lastSavedHash;
  }

  setOrderType(type?: OrderType) {
    this._orderType = type;
  }

  isOnSiteInspection() {
    return this.orderType === OrderType.ON_SITE_INSPECTION;
  }

  setOrderConfirmationDialogDisplayed(status: boolean): void {
    this._orderConfirmationDialogDisplayed = status;
  }

  get hasOrderConfirmationDialogDisplayed(): boolean {
    return this._orderConfirmationDialogDisplayed;
  }

  get orders(): Array<Order> {
    return this._orders.sort((a: Order, b: Order) => {
      if (a.appointment.start && b.appointment.start) {
        return (
          Date.parse(a.appointment.start) - Date.parse(b.appointment.start)
        );
      }
      return 0;
    });
  }

  get activeOrderId(): string | undefined {
    return this._activeOrder?.orderId;
  }

  get activeOrder(): Order | undefined {
    return this._activeOrder;
  }

  get pendingOrders() {
    return this.orders.filter(
      (order: Order) => order.status === OrderStatus.OPEN
    );
  }

  get activeOrders() {
    return this.orders.filter(
      (order: Order) => order.status === OrderStatus.IN_PROGRESS
    );
  }

  get closedOrders() {
    return this.orders.filter(
      (order: Order) => order.status === OrderStatus.CLOSED
    );
  }

  get selectedOrder() {
    return this._activeOrder;
  }

  resetInstallerNote() {
    if (!this._activeOrder) return;
    this._activeOrder.installerNote = '';
  }

  getOrderById(orderId: string) {
    return this._orders.find((order: Order) => order.id === orderId);
  }

  async fetchAllOrders() {
    this._orders = await fetchAllAssignedOrders();
  }

  clearOrders() {
    this._orders = [];
    this._activeOrder = undefined;
  }

  async startOrder(id: string) {
    const order = this._orders.find(
      (orderElement: Order) => orderElement.id === id
    );

    if (!order) {
      console.error(`Can't find order with id: ${id}`);
      return;
    }

    const activeOrder = await usePersistenceStore().getActiveOrder(order.id);

    if (!activeOrder) {
      usePersistenceStore().setActiveOrder(order);
    }

    const mapPlainDiffsToClass = (diffs: TransactionDiff[] | undefined) =>
      diffs?.map(
        (diff) =>
          new TransactionDiff(
            diff?.oldValue,
            diff?.newValue,
            diff?.path?.split('.') ?? [],
            diff?.type
          )
      ) ?? [];

    const mapPlainTransactionsToClass = (transactions: Record<string, any>) =>
      Object.entries(transactions).reduce(
        (mappedTransactions, [key, value]) => {
          const diff = mapPlainDiffsToClass(value?.diff);
          mappedTransactions[key] = new EntityTransaction(
            value?.entity,
            value?.entityType,
            value?.entityId,
            value?.type,
            value?.timestamp,
            diff,
            value?.originalEntityHash
          );
          return mappedTransactions;
        },
        {} as Record<string, EntityTransaction>
      );

    this._activeOrder = order;
    this._activeOrder.changes = plainToInstance(
      OrderChanges,
      activeOrder?.changes ?? order?.changes ?? {}
    );
    this._activeOrder.changes.transactions = mapPlainTransactionsToClass(
      this._activeOrder.changes.transactions
    );

    this.setOrderConfirmationDialogDisplayed(false);
    this.setOrderType(order.workTypeCode);

    if (order.status !== OrderStatus.IN_PROGRESS) {
      try {
        await saveOrderStatus(id, OrderStatus.IN_PROGRESS);
        order.status = OrderStatus.IN_PROGRESS;
      } catch (e) {
        console.error(e);
      }
    }
  }

  clearActiveOrderInfo() {
    if (this._activeOrder) {
      const businessEntityId = this._activeOrder.businessEntityId;
      useEntitiesStore().removeBusinessEntityFromList(businessEntityId);
      usePersistenceStore().clearLocalOrder(
        businessEntityId,
        this._activeOrder.id
      );

      const order = this._orders.find(
        (order) => order.id === this._activeOrder?.id
      );
      if (order) {
        order.status = OrderStatus.CLOSED;
      }
    }
    this._activeOrder = undefined;
  }

  finishOrder() {
    if (!this._activeOrder) {
      console.error(`Can't find order ${this._activeOrder}`);
      return;
    }
    const orderId = this._activeOrder.id;
    putOrderDetails(
      orderId,
      this.activeOrder?.installerNote ? this._activeOrder.installerNote : ''
    ).catch((error) => {
      console.error('Failed to put order details:', error);
    });

    return saveOrderStatus(orderId, OrderStatus.CLOSED)
      .then(() => {
        this.clearActiveOrderInfo();
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  toggleRoomGroupProgress(roomGroup: Roomgroup) {
    this.changeRoomGroupProgress(roomGroup);
  }

  isRoomGroupCompleted(roomGroup: Roomgroup) {
    return this._activeOrder?.changes.hasRoomGroupProgress(roomGroup.id);
  }

  changeRoomGroupProgress(roomGroup: Roomgroup) {
    if (!this._activeOrder) {
      return;
    }

    if (this._activeOrder?.changes.hasRoomGroupProgress(roomGroup.id)) {
      this._activeOrder?.changes.removeRoomGroupProgress(roomGroup.id);
    } else {
      this._activeOrder?.changes.addRoomGroupProgress(roomGroup.id);
    }

    usePersistenceStore().updateOrderChanges(
      this._activeOrder.id,
      this._activeOrder.changes
    );
  }

  removeRoomgroupProgress(roomGroup: Roomgroup) {
    if (!this._activeOrder) {
      return;
    }
    if (this._activeOrder?.changes.hasRoomGroupProgress(roomGroup.id)) {
      this._activeOrder?.changes.removeRoomGroupProgress(roomGroup.id);
    }

    usePersistenceStore().updateOrderChanges(
      this._activeOrder.id,
      this._activeOrder.changes
    );
  }

  private trackAutoSave(saveSuccess: boolean, errorMessage?: string) {
    const payload = {
      saveSuccess,
      errorMessage: errorMessage || null,
      orderId: this._activeOrder?.id || undefined,
      propertyId: this._activeOrder?.propertyId || undefined,
      businessEntityId: this._activeOrder?.businessEntityId || undefined,
      installerId: this._activeOrder?.installerId || undefined,
      timestamp: new Date().toISOString(),
    };
    this._analytics?.captureEvent('auto_save', payload);
  }

  triggerAutoSave() {
    if (!useLayoutStore().isOnline) return;
    // if (envConfig.isDevelopment === 'true') {
    //   console.warn('auto save disabled in development mode');
    //   return;
    // }
    if (!this._activeOrder) {
      return this.onActiveOrderNotFound();
    }
    const orderChanges = this._activeOrder.changes;
    if (orderChanges.unsavedTransactionCount > 10 && !this.isAutoSaving) {
      this.setIsAutoSaving(true);
      const waitingForUserToIdle = setInterval(async () => {
        if (useLayoutStore().isUserIdle) {
          clearInterval(waitingForUserToIdle);
          await this.autoSave();
        }
      }, 1000);
    }
  }

  async onActiveOrderNotFound() {
    if (await useUserStore().isUserDeveloper()) {
      console.warn('No active order found but user is developer');
      return false;
    } else {
      throw new Error('No active order found');
    }
  }

  async autoSave() {
    try {
      await usePersistenceStore().saveData(true);
      await useImagesStore().uploadImages();
      this.setIsAutoSaving(false);
      this.trackAutoSave(true);
      useLayoutStore().resetIsUserIdle();
      console.info('Auto save successful');
    } catch (error) {
      console.error('Error saving data:', error);
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      this.trackAutoSave(false, errorMessage);
      this.setIsAutoSaving(false);
    }
  }

  handleSaveEntityTransactions(entity: Entity) {
    if (!this._activeOrder) return;

    const changes: OrderChanges = this._activeOrder.changes;
    const entityId = entity.id;
    const existingTransaction = changes.transactions[entityId];
    const existingEntity = useEntitiesStore().hasEntityById(entityId)
      ? useEntitiesStore().getEntityById(entityId)
      : undefined;

    if (!existingEntity && !existingTransaction) {
      changes.upsertTransaction(
        entity,
        EntityTransactionType.CREATE_ENTITY,
        [],
        instanceToInstance(entity)
      );
      this.finalizeTransactionUpdate();
      return;
    }

    let originalEntity = existingEntity;
    let transactionType = EntityTransactionType.UPDATE_ENTITY;

    if (existingTransaction && existingTransaction.originalEntity) {
      originalEntity = existingTransaction.originalEntity;
      transactionType = existingTransaction.type;
    }

    const originalEntityCloned = instanceToInstance(originalEntity);
    const newEntityCloned = instanceToInstance(entity);

    const currentDiff = generateDiff(originalEntityCloned, newEntityCloned);

    if (
      currentDiff.length === 0 &&
      existingTransaction?.type !== EntityTransactionType.CREATE_ENTITY
    ) {
      console.debug(
        'Transaction reverted, deleting transaction for entity:',
        entityId
      );
      delete changes.transactions[entityId];
      this.finalizeTransactionUpdate();
      return;
    }

    changes.upsertTransaction(
      entity,
      transactionType,
      currentDiff as DifferenceChange[],
      originalEntityCloned
    );
    this.finalizeTransactionUpdate();
  }

  private finalizeTransactionUpdate() {
    this._activeOrder?.changes.addUnsavedTransaction();
    this.saveOrderChanges().then(() =>
      console.debug('Order save changes updated')
    );
    this.triggerAutoSave();
  }

  handleDeleteEntityTransactions(entity: Entity) {
    if (!this._activeOrder) {
      return;
    }

    const changes = this._activeOrder.changes;

    const isEntityAlreadyInTransaction = !!changes.transactions[entity.id];
    const newEntityInTransaction = useEntitiesStore().hasEntityById(entity.id);

    if (isEntityAlreadyInTransaction) {
      delete changes.transactions[entity.id];
    } else if (newEntityInTransaction) {
      this._activeOrder.changes.upsertTransaction(
        entity,
        EntityTransactionType.DELETE_ENTITY
      );
    }
    this.finalizeTransactionUpdate();
  }

  async saveOrderChanges() {
    if (!this._activeOrder) {
      return this.onActiveOrderNotFound();
    }

    return this._activeOrder.changes.onOrderChangesUpdate().then(() => {
      if (!this._activeOrder) return;
      usePersistenceStore().updateOrderChanges(
        this._activeOrder.id,
        this._activeOrder.changes
      );
    });
  }

  updateLastSavedOnActiveOrder() {
    const activeOrder = this._activeOrder;
    if (!activeOrder) return;

    return activeOrder.changes.onOrderChangesUpdate().then(() => {
      activeOrder.changes.onSaveOrderChanges();

      usePersistenceStore().updateOrderChanges(
        activeOrder.id,
        activeOrder.changes
      );
    });
  }

  getOrderChanges() {
    if (this._activeOrder) {
      return this._activeOrder.changes;
    }
    return undefined;
  }

  getOrderAddresses() {
    const addresses = [];
    if (this._activeOrder?.primaryAddress) {
      addresses.push(this._activeOrder?.primaryAddress);
    }
    addresses.push(...(this._activeOrder?.additionalAddresses ?? []));
    return addresses;
  }

  hasTransaction(id: string, type?: EntityTransactionType): boolean {
    const transaction = useOrderStore().getOrderChanges()?.transactions[id];
    return type ? transaction?.type === type : transaction !== undefined;
  }

  isFirstInstallationOrder() {
    return this._activeOrder?.detail?.isFirstInstallation === true;
  }

  unfinishedRoomGroups(): Map<string, Roomgroup> {
    if (
      !this.hasOrderConfirmationDialogDisplayed ||
      !this.isFirstInstallationOrder()
    )
      return new Map<string, Roomgroup>();
    const roomGroups = useRoomGroupStore().roomGroups;
    const orderChanges = this.activeOrder?.changes;

    const unfinished = new Map<string, Roomgroup>();
    for (const rg of roomGroups.values()) {
      if (['LIVING', 'BUSINESS'].includes(rg.usageType)) {
        if (rg.isAccessible && !orderChanges?.hasRoomGroupProgress(rg.id)) {
          unfinished.set(rg.id, rg);
        }
      }
    }
    return unfinished;
  }
}
