<template>
  <article class="flex flex-row rounded-md p-4 text-sm" :class="css">
    <em
      :class="[
        'mdi',
        icon ? icon : defaultIcon,
        iconColor,
        'flex-col self-center pr-2 text-xl',
      ]"
    />
    <div class="flex-co flex-grow self-center whitespace-pre-line">
      <div>{{ informationText }}</div>
      <slot></slot>
    </div>
    <div class="align-mid flex-col justify-center self-center">
      <slot name="button"></slot>
    </div>
  </article>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Status } from '@/enums/generic';

const props = defineProps<{
  informationText: string;
  icon?: string;
  status?: Status;
}>();

const css = computed(() => {
  switch (props.status) {
    case Status.INFO:
      return 'border-l-4 border-primary-1 bg-primary-0/10';
    case Status.SUCCESS:
      return 'border-l-4 border-dark-success bg-dark-success/10';
    case Status.WARNING:
      return 'border-l-4 border-dark-warning bg-dark-warning/10';
    case Status.ERROR:
      return 'border-l-4 border-dark-error bg-dark-error/10';
    default:
      return '';
  }
});

const defaultIcon = computed(() => {
  switch (props.status) {
    case Status.INFO:
      return 'mdi-information-outline';
    case Status.SUCCESS:
      return 'mdi-check-circle-outline';
    case Status.WARNING:
      return 'mdi-alert-box-outline';
    case Status.ERROR:
      return 'mdi-alert-outline';
    default:
      return '';
  }
});

const iconColor = computed(() => {
  switch (props.status) {
    case Status.INFO:
      return 'text-primary-2';
    case Status.SUCCESS:
      return 'text-light-success';
    case Status.WARNING:
      return 'text-light-warning';
    case Status.ERROR:
      return 'text-light-error';
    default:
      return '';
  }
});
</script>
