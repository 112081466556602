<template>
  <div class="flex flex-row self-center md:self-auto">
    <div class="relative">
      <button
        :class="[
          'rounded-full p-6 py-2 text-white transition-all ',
          { 'is-loading': isSaving },
          { 'is-justify-content-start': saved && hasActiveOrder },
          {
            'bg-primary-2 dark:bg-primary-1 hover:dark:bg-primary-2':
              !errorStatus.hasError,
          },
          {
            'dark:bg-dark-error-0 hover:dark:bg-dark-error-0 bg-light-error/10 hover:bg-light-error':
              errorStatus.hasError,
          },
        ]"
        :disabled="isSaving"
        data-testid="uploadButton"
        @click="startSaveProcess"
      >
        <em
          :class="[
            'mdi leading-none',
            { 'mdi-check': saved },
            { 'mdi-loading mdi-spin': isSaving },
          ]"
        ></em>
        {{ saveButtonLabel }}
      </button>

      <div v-if="lastSaved && savedToday" class="mr-6 pt-1 text-right text-sm">
        zuletzt um {{ lastSavedTime }}
      </div>
      <ImageUploadHeader ref="imageUpload" class="mt-[-40px] w-full" />
    </div>
    <div>
      <button
        v-if="hasActiveOrder"
        :class="[
          'ml-3 rounded-full px-6 py-2 transition-all duration-300 ease-in-out',
          {
            'cursor-not-allowed bg-light-disabled-background dark:bg-dark-disabled-background':
              !saved,
          },
          {
            'bg-primary-2 text-white dark:bg-primary-1 hover:dark:bg-primary-2 ':
              saved,
          },
        ]"
        :disabled="!saved || !isOnline"
        data-testid="closeOrderBtn"
        @click="openDialog"
      >
        <span
          :class="[
            {
              'text-light-disabled-text dark:text-dark-disabled-text': !saved,
            },
          ]"
          >Auftrag abschließen</span
        >
      </button>
    </div>
  </div>
  <BasePrompt
    :open="modalOpen"
    :close="toggleModal"
    :cancel="toggleModal"
    :proceed="onProceed"
    :icon="promptData.icon"
    :title="promptData.title"
    :question="promptData.question"
    :proceed-text="promptData.proceedText"
    :cancel-text="promptData.cancelText"
  >
    <template v-if="unfinishedRoomGroups.size > 0">
      <div class="mx-auto max-h-[80vh] w-full max-w-[90%] overflow-auto">
        <div class="text-left">
          <div
            v-for="roomGroup in unfinishedRoomGroups.values()"
            :key="roomGroup.id"
          >
            <b>{{ getDisplayName(roomGroup) }}</b>
          </div>
        </div>
      </div>
    </template>

    <template v-if="isCompleted">
      <div class="mt-4 flex">
        <em class="mdi mdi-information-outline text-primary-2"></em>
        <div
          class="ml-2 max-h-[80vh] max-w-[90%] flex-1 overflow-auto text-left"
        >
          Ein abgeschlossener Auftrag kann nicht nachträglich bearbeitet werden.
        </div>
      </div>
    </template>
  </BasePrompt>
</template>

<script lang="ts" setup>
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { computed, ref } from 'vue';
import { useOrderStore } from '@/store/order/orderStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import ImageUploadHeader from '@/components/Header/ImageUploadHeader.vue';
import { useFormStore } from '@/store/form/formStore';
import { useCloseOrderButton } from '@/composables/useCloseOrderButton';
import i18n from '@/utils/i18n/order.json';
import { Notification, Roomgroup } from '@/models';
import { useRoomStore } from '@/store/entities/roomStore';
import { useBuildingStore } from '@/store/entities/buildingStore';

const persistenceStore = usePersistenceStore();
const orderStore = useOrderStore();

const saved = computed(() => {
  return persistenceStore.saved;
});

const isFirstInstallation = computed(() => {
  return orderStore.activeOrder?.detail?.isFirstInstallation;
});

const unfinishedRoomGroups = computed(() => {
  return isFirstInstallation.value
    ? orderStore.unfinishedRoomGroups()
    : new Map<string, Roomgroup>();
});

const isCompleted = computed(() => {
  return isFirstInstallation.value && unfinishedRoomGroups.value?.size === 0;
});

const promptData = computed(() => {
  if (!isFirstInstallation.value) {
    return {
      title: i18n.closeOrderPrompt.title.default,
      question: i18n.closeOrderPrompt.body.default,
      proceedText: i18n.closeOrderPrompt.buttons.default.proceed,
      cancelText: i18n.closeOrderPrompt.buttons.default.cancel,
    };
  }
  if (isCompleted.value) {
    return {
      icon: Notification.SUCCESS,
      title: i18n.closeOrderPrompt.title.firstInstallation.completed,
      question: i18n.closeOrderPrompt.body.firstInstallation.completed,
      proceedText:
        i18n.closeOrderPrompt.buttons.firstInstallation.proceed.completed,
      cancelText:
        i18n.closeOrderPrompt.buttons.firstInstallation.cancel.completed,
    };
  } else {
    return {
      icon: Notification.ERROR,
      title: i18n.closeOrderPrompt.title.firstInstallation.incomplete,
      question: i18n.closeOrderPrompt.body.firstInstallation.incomplete,
      proceedText:
        i18n.closeOrderPrompt.buttons.firstInstallation.proceed.incomplete,
      cancelText:
        i18n.closeOrderPrompt.buttons.firstInstallation.cancel.incomplete,
    };
  }
});

const getDisplayName = (roomGroup: Roomgroup) => {
  const room = useRoomStore().getFirstRoomOfRoomgroup(roomGroup.id);
  const building = useBuildingStore().getCurrentBuilding();
  return (
    room?.getFloor() +
    ' ' +
    roomGroup.doorbellName +
    ', ' +
    building?.shortAddress
  );
};

const isSaving = computed(() => {
  return persistenceStore.isSaving.value;
});

const saveButtonLabel = computed(() => {
  return saved.value ? ' Daten gespeichert' : 'Daten speichern';
});

const lastSaved = computed(() => {
  return orderStore.activeOrder?.changes.lastSaved;
});

const savedToday = computed(() => {
  return orderStore.activeOrder?.changes.savedToday;
});

const lastSavedTime = computed(() => {
  return orderStore.activeOrder?.changes.lastSavedTime;
});

const errorStatus = computed(() => {
  return persistenceStore.errorStatus;
});

const hasActiveOrder = computed(() => {
  return !!orderStore.activeOrderId;
});

const imageUpload = ref<null | { uploadImages: () => Promise<void> }>(null);

const isOnSiteInspection = computed(() => {
  return useOrderStore().isOnSiteInspection();
});

const openDialog = () => {
  return isOnSiteInspection.value ? openOrderForm() : toggleModal();
};

const openOrderForm = () => {
  const layoutStore = useLayoutStore();
  layoutStore.openFormDialog();
  useFormStore().setCurrentFormByName('Order', false);
};

const { isOnline, modalOpen, startSaveProcess, toggleModal, finishOrder } =
  useCloseOrderButton();

const onProceed = () => {
  if (!isFirstInstallation.value || isCompleted.value) {
    finishOrder();
  } else {
    toggleModal();
  }
};
</script>
