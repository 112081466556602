<template>
  <DeviceTypeSelection
    v-if="!deviceType"
    :device-type="deviceType"
    @update:device-type="(newValue) => onDeviceTypeSelection(newValue)"
  />
  <NoMountingSelector
    v-if="isNoMountingSelectorVisible"
    ref="noMountingSelectorRef"
    v-model:isMounted="isMounted"
    v-model:failedInstallationAttempt="failedInstallationAttempt"
    :device-type="deviceType"
    :device="device"
    :installation-point="installationPoint"
  />

  <BaseSection
    v-if="deviceType === DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT && !isMounted"
    heading="Adapter"
    :required="false"
  >
    <AccessoryList ref="accessoryListRef" v-model="installationPoint" />
  </BaseSection>
  <BasePrompt
    title="Dieser Raum ist als Raum ohne Heizkörper definiert."
    :proceed="onConfirmHasRadiator"
    :open="hasRadiatorPromptOpen"
    :close="useLayoutStore().closeFormDialog"
    :cancel="useLayoutStore().closeFormDialog"
    proceed-text="Heizkörper vorhanden"
  />

  <template v-if="isMounted || deviceType == deviceTypes.HeatCostAllocator">
    <RecordOldDevice
      v-if="isOldDeviceVisible"
      ref="oldFormComponentRef"
      v-model:old-device="oldDevice"
      v-model:old-device-event="oldDeviceEvent"
      :reset-old-device="() => initOldDevice(deviceType, installationPoint)"
    />

    <component
      :is="formComponent"
      v-if="deviceType && installationPoint && device && deviceEvent"
      ref="formComponentRef"
      v-model:device="device"
      v-model:deviceEvent="deviceEvent"
      v-model:installationPoint="installationPoint"
      v-bind="armRelatedProps"
      data-testid="deviceForm"
      @close-and-save-form="handleSubmit"
    />
  </template>
</template>

<script setup lang="ts">
import {
  computed,
  defineAsyncComponent,
  onBeforeMount,
  provide,
  ref,
  Ref,
  watch,
} from 'vue';
import DeviceTypeSelection from '@/components/Forms/DeviceFormComponents/DeviceTypeSelection.vue';
import { useDeviceForm } from '@/composables/useDeviceForm';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { useOldDeviceForm } from '@/composables/useOldDeviceForm';
import RecordOldDevice from '@/components/Forms/DeviceFormComponents/RecordOldDevice.vue';
import NoMountingSelector from '@/components/Forms/DeviceFormComponents/NoMountingSelector.vue';
import {
  DeviceClasses,
  DeviceType,
  isLegacyDevice,
} from '@/models/devices/Device';
import { DeviceTypeEnum, deviceTypes } from '@/enums/device';
import { Radiator } from '@/models/radiators/Radiator';
import { FormAction, useFormStore } from '@/store/form/formStore';
import BaseSection from '@/components/UI/Card/BaseSection.vue';
import AccessoryList from '@/components/Forms/DeviceFormComponents/AccessoryList.vue';
import { useRoomStore } from '@/store/entities/roomStore';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { useLayoutStore } from '@/store/layout/layoutStore';

const props = defineProps<{
  node?: InstallationPoint;
  parentId: string;
  payload?: any;
}>();
const emit = defineEmits(['update-entity', 'update-entities']);

const {
  device,
  installationPoint,
  deviceEvent,
  failedInstallationAttempt,
  initDevice,
  initAccessories,
  initInstallationPoint,
  createDevice: createNewDevice,
  updateDevice: updateNewDevice,
  updateAccessories,
} = useDeviceForm(emit);

const {
  device: oldDevice,
  deviceEvent: oldDeviceEvent,
  initOldDevice: initOldDevice,
  createDevice: createOldDevice,
  updateDevice: updateOldDevice,
} = useOldDeviceForm(emit);

const deviceType = ref(props.payload?.deviceType);
const hasOldDevice = ref(false);
const isMounted = ref(true);

const hasRadiatorPromptOpen = ref(false);
const onDeviceTypeSelection = (newDeviceType: DeviceType) => {
  deviceType.value = newDeviceType;
  const roomId = props.parentId;
  let placeholder;

  if (
    newDeviceType === deviceTypes.SmartRadiatorThermostat &&
    useRoomStore().getRoomById(roomId).hasRadiator === false
  ) {
    hasRadiatorPromptOpen.value = true;
  }

  initInstallationPoint(roomId);
  if (!installationPoint.value || !deviceType.value) {
    throw new Error('Something went wrong with init functions');
  }

  initNewDevice(deviceType.value, installationPoint.value);
  useFormStore().changeCurrentNode(device.value);
};

onBeforeMount(() => {
  if (useFormStore().formAction === FormAction.CREATE) {
    initInstallationPoint(props.parentId);
    if (props.payload?.radiatorId) {
      installationPoint.value?.setRadiatorId(props.payload.radiatorId);
    }
    if (props.payload?.deviceType) {
      if (!installationPoint.value) {
        throw new Error('Installation point is not initialized');
      }
      initNewDevice(props.payload?.deviceType, installationPoint.value);
    }
  }

  if (useFormStore().formAction === FormAction.EDIT) {
    if (!props.node) {
      throw new Error('Node is not defined');
    }
    deviceType.value = props.node.supportedDeviceType;
    isMounted.value = !props.node?.failedInstallationAttempt;
    initInstallationPoint(props.parentId, props.node);

    if (isMounted.value) {
      initExistingDevices(props.node);
    }
  }

  if (useFormStore().formAction === FormAction.MOUNT) {
    if (!props.node) {
      throw new Error('No node was provided');
    }
    deviceType.value = props.node.supportedDeviceType;
    initInstallationPoint(props.parentId, props.node);
    initNewDevice(deviceType.value, props.node);
    isMounted.value = true;
  }

  if (useFormStore().formAction === FormAction.REPLACE) {
    if (!props.node) {
      throw new Error('Installation point is not initialized');
    }
    deviceType.value = props.node.supportedDeviceType;
    initInstallationPoint(props.parentId, props.node);
    initNewDevice(deviceType.value, props.node);
    if (props.node.activeDeviceId) {
      const activeDevice = props.node.getActiveDevice();
      hasOldDevice.value = true;
      initOldDevice(deviceType.value, props.node, activeDevice);
    }
    isMounted.value = !installationPoint.value?.failedInstallationAttempt;
  }
});

watch(
  () => isMounted.value,
  (isMounted) => {
    if (isMounted) {
      installationPoint.value?.removeFailedInstallationAttempt();
      if (useFormStore().formAction === FormAction.EDIT)
        if (installationPoint.value?.activeDeviceId)
          initExistingDevices(installationPoint.value);
        else initNewDevice(deviceType.value, installationPoint.value);
      if (useFormStore().formAction === FormAction.REPLACE) {
        if (installationPoint.value?.activeDeviceId) {
          initOldDevice(
            deviceType.value,
            props.node,
            props.node?.getActiveDevice()
          );
        }
      }
    }
    updateAccessories(isMounted);
  }
);

// ARM Stuff needs refactoring
const armInstallationPoints = ref<InstallationPoint[]>([]);
const updateArmInstallationPoints = (updatedArray: InstallationPoint[]) => {
  armInstallationPoints.value = updatedArray;
};

const armRelatedProps = computed(() => {
  let armRelatedProps = {};
  if (
    deviceType.value === deviceTypes.HeatMeter ||
    deviceType.value === deviceTypes.WarmWaterMeter ||
    deviceType.value === deviceTypes.WaterMeter
  ) {
    armRelatedProps = {
      armInstallationPoints: armInstallationPoints.value,
      updateArmInstallationPoints: updateArmInstallationPoints,
    };
  }
  return armRelatedProps;
});

const isFailedInstallationAttempt = () =>
  !isMounted.value && failedInstallationAttempt.value?.reason;

// Init Devices
const initExistingDevices = (installationPoint: InstallationPoint) => {
  const activeDevice = installationPoint?.getActiveDevice() as DeviceClasses;
  deviceType.value = activeDevice.deviceType;
  initDevice(activeDevice.deviceType, activeDevice);
  initAccessories();
  initOldDevice(activeDevice.deviceType, installationPoint);

  const previousDevice = installationPoint.getPreviousDevice();
  if (previousDevice) {
    hasOldDevice.value = true;
  }
};

const initNewDevice = (
  deviceType: DeviceType,
  installationPoint: InstallationPoint
) => {
  initDevice(deviceType);
  initAccessories();
  initOldDevice(deviceType, installationPoint);
};

// Handle Submit
const handleSubmit = (radiator?: Radiator) => {
  if (isFailedInstallationAttempt()) {
    if (deviceType.value === DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT) {
      useRoomStore().setRoomHasRadiator(props.parentId, true);
    }
    installationPoint.value?.addFailedInstallationAttempt(
      failedInstallationAttempt.value,
      deviceType.value
    );
    if (
      noMountingSelectorRef.value?.saveImage &&
      failedInstallationAttempt.value
    ) {
      noMountingSelectorRef.value?.saveImage(
        failedInstallationAttempt.value.id
      );
    }
    emit('update-entities', [installationPoint.value]);
    return;
  }

  if (useFormStore().formAction === FormAction.CREATE) {
    if (oldDevice.value?.serialNumber) {
      createOldDevice();
    }
    createNewDevice();
  }

  if (useFormStore().formAction === FormAction.MOUNT) {
    installationPoint.value?.removeFailedInstallationAttempt();

    if (oldDevice.value?.serialNumber) {
      createOldDevice();
    }

    installationPoint.value?.mountDevice(
      device.value.id,
      deviceEvent.value.timestamp,
      deviceEvent.value.counter
    );
    updateNewDevice();
  }

  if (useFormStore().formAction === FormAction.EDIT) {
    if (
      noMountingSelectorRef.value?.saveImage &&
      failedInstallationAttempt.value
    ) {
      noMountingSelectorRef.value?.saveImage(
        failedInstallationAttempt.value.id
      );
    }

    updateNewDevice();
  }

  if (useFormStore().formAction === FormAction.REPLACE) {
    if (device.value.isLegacyDevice()) {
      installationPoint.value?.recaptureDevice(
        device.value,
        deviceEvent.value.counter
      );
      updateNewDevice();
    } else {
      installationPoint.value?.replaceDevice(
        device.value,
        deviceEvent.value,
        oldDevice.value,
        oldDeviceEvent.value
      );
      updateNewDevice();
    }
  }

  if (radiator) {
    useRoomStore().setRoomHasRadiator(props.parentId, true);
    emit('update-entities', [installationPoint.value, radiator]);
  }

  if (armInstallationPoints.value.length > 0) {
    emit('update-entities', armInstallationPoints.value);
    armInstallationPoints.value = [];
  }

  if (formComponentRef.value?.saveRadiator) {
    useRoomStore().setRoomHasRadiator(props.parentId, true);
    formComponentRef.value?.saveRadiator();
  }

  if (formComponentRef.value?.saveImage) {
    if (installationPoint.value) {
      formComponentRef.value?.saveImage(installationPoint.value.id);
    }
  }
};

const onConfirmHasRadiator = () => {
  hasRadiatorPromptOpen.value = false;
};

// Toggle visibility
const isNoMountingSelectorVisible = computed(() => {
  if (useFormStore().formAction === 'EDIT' && isMounted.value) {
    return false;
  }
  if (deviceType.value === 'HEAT_COST_ALLOCATOR') {
    return useFormStore().editNode?.type === 'device';
  } else {
    return !!deviceType.value && !isLegacyDevice(deviceType.value);
  }
});

const isMountedToggleEnabled = computed(() => {
  return !(useFormStore().formAction === FormAction.EDIT && !isMounted.value);
});

const isOldDeviceVisible = computed(() => {
  if (
    deviceType.value === DeviceTypeEnum.HEAT_COST_ALLOCATOR ||
    (deviceType.value === DeviceTypeEnum.LO_RA_WAN_GATEWAY &&
      useFormStore().formAction === FormAction.CREATE) ||
    (useFormStore().formAction === FormAction.EDIT && !hasOldDevice.value) ||
    (deviceType.value === DeviceTypeEnum.SMART_RADIATOR_THERMOSTAT &&
      useFormStore().formAction === FormAction.CREATE)
  ) {
    return false;
  }
  return !!deviceType.value;
});

type FormComponentRef = {
  isFormValid: boolean;
  hasImage: boolean;
  saveImage: (id: string) => void;
  saveRadiator: () => void;
};
type OldFormComponentRef = {
  isFormValid: boolean;
};
type NoMountingSelectorRef = {
  isFormValid: boolean;
  saveImage: (id: string) => void;
};
type AccessoryListRef = {
  isFormValid: boolean;
};
const formComponentRef: Ref<FormComponentRef | null> = ref(null);
const oldFormComponentRef: Ref<OldFormComponentRef | null> = ref(null);
const noMountingSelectorRef: Ref<NoMountingSelectorRef | null> = ref(null);
const accessoryListRef: Ref<AccessoryListRef | null> = ref(null);

const isFormValid = computed(() => {
  if (isFailedInstallationAttempt()) {
    return (
      noMountingSelectorRef.value?.isFormValid &&
      (accessoryListRef.value?.isFormValid ?? true)
    );
  } else {
    const isReplacementFormValid =
      oldFormComponentRef.value?.isFormValid !== false;
    return formComponentRef.value?.isFormValid && isReplacementFormValid;
  }
});

const isNoMountingFormValid = computed(() => {
  return noMountingSelectorRef.value?.isFormValid;
});

defineExpose({
  isFormValid,
  handleSubmit,
});

provide('isNoMountingFormValid', isNoMountingFormValid);
provide('isMounted', isMounted);
provide('failedInstallationAttempt', failedInstallationAttempt);
provide('oldDevice', oldDevice);
provide('oldDeviceEvent', oldDeviceEvent);
provide('resetOldDeviceFunction', () =>
  initOldDevice(deviceType.value, installationPoint.value)
);

const deviceComponents: any = {
  SMOKE_DETECTOR: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/BasicDeviceForm.vue'
  ),
  HEAT_ENERGY_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/BasicDeviceForm.vue'
  ),
  WARM_WATER_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/BasicDeviceForm.vue'
  ),
  WATER_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/BasicDeviceForm.vue'
  ),
  DIRECT_METER_GATEWAY: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/DirectMeterGatewayForm.vue'
  ),
  HEAT_COST_ALLOCATOR: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/HeatCostAllocatorForm.vue'
  ),
  SMART_RADIATOR_THERMOSTAT: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/SmartRadiatorThermostatForm.vue'
  ),
  LO_RA_WAN_GATEWAY: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LoRaWanGatewayForm.vue'
  ),
  LEGACY_HEAT_ENERGY_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LegacyMeteringDeviceForm.vue'
  ),
  LEGACY_WARM_WATER_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LegacyMeteringDeviceForm.vue'
  ),
  LEGACY_WATER_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LegacyMeteringDeviceForm.vue'
  ),
  LEGACY_HEATING_PLANT_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LegacyPlantMeterForm.vue'
  ),
  LEGACY_WATER_PLANT_METER: import(
    '@/components/Forms/DeviceFormComponents/DeviceTypeForms/LegacyPlantMeterForm.vue'
  ),
};

const formComponent = computed(() => {
  const deviceTypeIndex = deviceType.value;
  if (deviceTypeIndex) {
    return defineAsyncComponent({
      loader: () => deviceComponents[deviceTypeIndex],
    });
  }
  return null;
});
</script>
