<template>
  <div
    class="flex h-16 min-w-[200px] cursor-pointer flex-row items-center rounded-xl align-middle shadow hover:bg-light-2 hover:dark:bg-dark-3 md:min-w-[300px] md:flex-row"
    :class="[
      isActiveBuilding
        ? 'border-b-2 border-primary-2 bg-light-2 dark:bg-dark-3'
        : 'border-dark-3 bg-light-1 dark:border dark:bg-dark-2',
    ]"
    :data-testid="isActiveBuilding ? 'buildingCard_active' : 'buildingCard'"
  >
    <div
      class="flex h-full grow flex-row items-center pl-4 font-bold"
      @click="changeActiveBuilding(building)"
    >
      <NoEntryIcon v-if="!building?.isAccessible" class="mr-2 text-2xl" />

      <div class="flex flex-col">
        <span>
          {{ building.getLabel() }}
        </span>
        <span class="truncate text-sm font-thin">
          {{ building.getEntranceInfo() }}
        </span>
      </div>
      <div v-if="containsUnfinishedRoomGroups">
        <em
          class="mdi mdi-help-circle ml-4 text-2xl text-light-error dark:text-dark-error"
        />
      </div>
    </div>

    <OptionsDropdown
      class="pr-2"
      :data-testid="
        isActiveBuilding
          ? 'buildingOptionsDropdown_active'
          : 'buildingOptionsDropdown'
      "
      icon="mdi-dots-vertical"
    >
      <li data-testid="editBuildingButton" @click="editBuilding(building)">
        <em class="mdi mdi-pencil mr-2" />Bearbeiten
      </li>
      <li
        data-testid="deleteBuildingButton"
        :class="[
          {
            'cursor-not-allowed text-light-disabled-text hover:bg-transparent dark:text-dark-disabled-text':
              !isBuildingDeletable,
          },
        ]"
        @click="isBuildingDeletable && togglePrompt()"
      >
        <em class="mdi mdi-delete mr-2" />Löschen
      </li>
    </OptionsDropdown>
  </div>
  <BasePrompt
    :open="promptOpen"
    :cancel="togglePrompt"
    :close="togglePrompt"
    :proceed="onDeleteBuilding"
    title="Gebäude löschen"
    question="Sind Sie sicher, dass Sie dieses Gebäude löschen möchten?"
  />
</template>
<script setup lang="ts">
import { Building } from '@/models';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { ref, computed, inject } from 'vue';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { useBuildingCard } from '@/composables/useBuildingCard';
import NoEntryIcon from '@/components/UI/Badge/NoEntryIcon.vue';
import { useOrderStore } from '@/store/order/orderStore';

const props = defineProps<{
  building: Building;
  isActiveBuilding: boolean;
}>();

const changeActiveBuilding = inject('changeActiveBuilding');
const editBuilding = inject('editBuilding');

const promptOpen = ref(false);
const togglePrompt = () => {
  promptOpen.value = !promptOpen.value;
};

const building = computed(() => {
  return props.building;
});

const containsUnfinishedRoomGroups = computed(() => {
  return Array.from(useOrderStore().unfinishedRoomGroups().values()).some(
    (rg) => rg.buildingId === building.value.id
  );
});

const { isBuildingDeletable, onDeleteBuilding } = useBuildingCard(building);
</script>
