import { generateUniqueId } from '@/helpers/uuidUtils';

export class Notification {
  public id: string;
  public text?: string;
  public title?: string;
  public type?: string;
  public timeout?: number;

  static ERROR = 'mdi-alert-outline text-light-error';
  static SUCCESS = 'mdi-check-circle-outline text-light-success';
  static INFO = 'mdi-information-outline text-primary-2';
  static WARNING = 'mdi-message-alert-outline text-light-warning';

  constructor(
    text = 'Ihre Änderungen wurden übernommen',
    title = '',
    type = 'info',
    timeout = 5000
  ) {
    this.id = generateUniqueId();
    this.text = text;
    this.title = title;
    this.type = type;
    this.timeout = timeout;
  }

  get notificationClass(): { [key: string]: string } {
    return {
      error: Notification.ERROR,
      success: Notification.SUCCESS,
      info: Notification.INFO,
      warning: Notification.WARNING,
    };
  }

  getCssClass() {
    const type = this.type;
    return this.notificationClass[type as string]
      ? this.notificationClass[type as string]
      : this.type;
  }

  setTitle(title: string) {
    this.title = title;
    return this;
  }

  setText(text: string) {
    this.text = text;
    return this;
  }
  setType(type: string) {
    this.type = type;
    return this;
  }
  setTimeout(timeout: number) {
    this.timeout = timeout;
    return this;
  }
}
