<template>
  <div v-if="currentRoomgroup" class="canvas-container">
    <div
      class="absolute z-10 flex w-full flex-col bg-light-0 pb-6 dark:bg-dark-0"
    >
      <div class="flex justify-between">
        <div class="flex flex-row items-center pt-8 font-light leading-10">
          <a
            :class="[
              'flex cursor-pointer flex-row items-center',
              {
                'text-light-disabled-text dark:text-dark-disabled-text':
                  isIncomplete,
              },
            ]"
            @click="goToBuildingOverview"
          >
            <em class="mdi mdi-chevron-left text-2xl leading-none" />
            {{ currentBuilding.shortAddress }}</a
          >
          <em class="mdi mdi-slash-forward" />
          <span>{{ firstRoom?.getFloor() }}</span>
          <em class="mdi mdi-slash-forward" />
          <span>{{ currentRoomgroup.ordinal }} </span>
        </div>
        <div
          v-if="lastSaved && savedToday"
          class="mr-[-28px] pt-1 text-sm dark:text-slate-400"
        >
          zuletzt gespeichert um {{ lastSavedTime }}
        </div>
      </div>
      <div class="ml-2 flex flex-row justify-between text-4xl font-bold">
        <div class="flex flex-row items-end">
          <div>
            <NoEntryIcon v-if="!currentRoomgroup?.isAccessible" />
            {{ currentRoomgroup.getLabel() }}
          </div>
          <div class="ml-3 pb-1 text-xs font-light text-gray-400">
            <div v-if="currentRoomgroup.number">
              Nr. {{ currentRoomgroup.number }}
            </div>
            <div v-if="currentRoomgroup.customerAssignmentReference">
              KDOB: {{ currentRoomgroup.customerAssignmentReference }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 flex flex-row gap-3">
        <div>
          <TertiaryButton
            :icon="isRoomGroupComplete ? 'mdi mdi-check-bold' : ''"
            class="rounded-xl bg-primary-3 !py-5 dark:bg-dark-1"
            data-testId="finishRoomGroupButton"
            :is-disabled="isBlocked"
            label="Fertig"
            @click="finishRoomgroup()"
          />
        </div>
        <BaseInformation
          v-if="roomGroupFinishedClicked && unclearRooms.length > 0"
          :information-text="`In ${unclearRooms.length} Räumen sind keine Angaben zu den Geräten vorhanden`"
          status="error"
          data-testId="unclearRooms_info"
        />
      </div>
    </div>

    <div
      id="wrapper"
      class="canvas-wrapper"
      :class="layoutStore.isDarkMode() ? 'canvas-bg-dark' : 'canvas-bg-light'"
    >
      <div id="myCanvas" class="myCanvas"></div>

      <div
        class="fixed right-10 top-28 flex gap-2 md:bottom-10 md:left-32 md:top-auto"
      >
        <div
          class="rounded-xl bg-light-1 px-3 py-2 dark:bg-dark-2"
          @click="resetCanvas"
        >
          <span class="mdi mdi-crosshairs-gps text-xl"></span>
        </div>
        <div
          class="rounded-xl bg-light-1 px-3 py-2 dark:bg-dark-2"
          @click="toggleCanvasLock"
        >
          <span
            v-if="isCanvasLocked"
            class="mdi mdi-lock-outline text-xl"
          ></span>
          <span v-else class="mdi mdi-lock-open-outline text-xl"></span>
        </div>
        <div
          class="rounded-xl bg-light-1 px-3 py-2 dark:bg-dark-2"
          :class="{ swapModeActive: isSwapModeActive }"
          @click="toggleSwapMode()"
        >
          <em class="mdi mdi-swap-horizontal text-xl"></em>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!currentRoomgroup" class="error-message">
    <span class="mdi mdi-alert"></span>
    Keine Raumgruppe ausgewählt, bitte wählen Sie zunächst eine in der
    Gebäudeübersicht
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, ComputedRef } from 'vue';
import { createCanvas } from '@/utils/canvas/drawing';
import { Node } from '@/utils/canvas/drawingUtils';
import { createTreeNode } from '@/utils/canvas/treeMapping';
import useStores from '@/composables/useStores';
import { Room } from '@/models';
import { Form } from '@/models/Form';
import { entityTypes } from '@/enums/generic';
import { useFormStore } from '@/store/form/formStore';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { routesNames } from '@/router';
import { useRouter } from 'vue-router';
import NoEntryIcon from '@/components/UI/Badge/NoEntryIcon.vue';
import { useRoomGroupProgress } from '@/composables/useRoomGroupProgress';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
const router = useRouter();
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';

const { layoutStore, roomGroupStore, entitiesStore, roomStore, orderStore } =
  useStores();

const isCanvasLocked = ref(false);
const canvas = ref<any>(undefined);
const isSwapModeActive = ref(false);
const node = ref<Node | undefined>(undefined);
const roomsOfRoomgroup = ref<Room[]>([]);
const firstRoom = ref<Room | undefined>(undefined);

const currentRoomgroup = computed(() => roomGroupStore.getCurrentRoomGroup);
const getBusinessEntityMap = computed(() => entitiesStore.businessEntityMap);

const getCurrentNode: ComputedRef<Room> = computed(
  () => layoutStore.currentNode as Room
);

const currentBuilding = computed(() => {
  return useBuildingStore().getCurrentBuilding();
});
const goToBuildingOverview = () => {
  if (isIncomplete.value) return;
  router.push({
    name: routesNames.BUILDING_OVERVIEW,
  });
};
let panzoom: PanzoomObject | undefined = undefined;

const roomGroupFinishedClicked = computed(() => {
  return roomGroupStore.isRoomGroupFinishedClicked(currentRoomgroup.value.id);
});

const unclearRooms = computed(() => {
  return roomsOfRoomgroup.value.filter((room) => {
    return !roomStore.isFinished(room.id);
  });
});

const isBlocked = computed(() => {
  const incompleteRooms = roomsOfRoomgroup.value.filter((room) => {
    return !roomStore.isFinished(room.id);
  });
  return incompleteRooms.length > 0 && roomGroupFinishedClicked.value;
});

const isIncomplete = computed(() => {
  const incompleteRooms = roomsOfRoomgroup.value.filter((room) => {
    return roomStore.isIncomplete(room.id);
  });
  return incompleteRooms.length > 0 && roomGroupFinishedClicked.value;
});

const isOnSiteInspection = computed(() => {
  return orderStore.isOnSiteInspection();
});

const finishRoomgroup = () => {
  if (
    unclearRooms.value.length === 0 ||
    currentRoomgroup.value?.usageType === 'GENERAL' ||
    isOnSiteInspection.value
  ) {
    completeRoomGroup();
  } else {
    roomGroupStore.addToRoomGroupsFinishedClicked(currentRoomgroup.value?.id);
    generateNodeCanvas();
  }
};

const isRoomGroupComplete = computed(() => {
  return orderStore.isRoomGroupCompleted(currentRoomgroup.value);
});

const { completeRoomGroup } = useRoomGroupProgress(currentRoomgroup.value);

watch(getCurrentNode, () => {
  generateNodeCanvas();
});

watch(
  getBusinessEntityMap,
  () => {
    generateNodeCanvas();
  },
  { deep: true }
);

onMounted(() => {
  const canvas = document.getElementById('myCanvas') as HTMLElement;
  panzoom = Panzoom(canvas);
  if (currentRoomgroup.value) {
    drawCanvas();
  }
});

const lastSaved = computed(() => {
  return orderStore.activeOrder?.changes?.lastSaved;
});

const savedToday = computed(() => {
  return orderStore.activeOrder?.changes?.savedToday;
});

const lastSavedTime = computed(() => {
  return orderStore.activeOrder?.changes?.lastSavedTime;
});

const toggleCanvasLock = () => {
  panzoom?.setOptions({
    disablePan: !isCanvasLocked.value,
    disableZoom: !isCanvasLocked.value,
  });
  isCanvasLocked.value = !isCanvasLocked.value;
};

const resetCanvas = () => {
  panzoom?.reset();
};

const toggleSwapMode = () => {
  isSwapModeActive.value = !isSwapModeActive.value;
  generateNodeCanvas();
};

const drawCanvas = () => {
  const canvasElement = document.getElementById(
    'myCanvas'
  ) as HTMLCanvasElement;
  if (!canvasElement) return;

  const parent = canvasElement.parentNode as HTMLElement;
  const styles = getComputedStyle(parent);

  const h = parseInt(styles.getPropertyValue('height'), 10);
  const w = parseInt(styles.getPropertyValue('width'), 10);
  canvasElement.width = w;
  canvasElement.height = h;
  const options = {
    boxWidth: 150,
    boxHeight: 136,
    marginX: 40,
    marginY: 30,
    maxDepth: 3,
    menu: {
      width: 180,
    },
    groups: {
      onClick: (currentGroup: Room) => {
        const group = entitiesStore.getEntityById(currentGroup.id);
        layoutStore.changeCurrentNode(group);
      },
      addRoom: () => {
        roomGroupStore.removeFromRoomGroupsFinishedClicked(
          currentRoomgroup.value?.id
        );
        useFormStore().createEntity(
          new Form()
            .setParentId(getCurrentNode.value?.id)
            .setName(entityTypes.room)
        );
      },
    },
  };

  if (!currentRoomgroup.value) return;
  roomsOfRoomgroup.value = roomStore.getRoomsByRoomGroupId(
    currentRoomgroup.value.id
  );

  firstRoom.value = roomStore.getFirstRoomOfRoomgroup(
    currentRoomgroup.value.id
  );

  if (
    getCurrentNode.value?.roomGroupId !== currentRoomgroup.value.id &&
    firstRoom.value
  ) {
    layoutStore.changeCurrentNode(firstRoom.value);
  }

  const treeNode = createTreeNode(
    firstRoom.value,
    roomsOfRoomgroup.value,
    getCurrentNode.value.id
  );

  node.value = treeNode;
  canvas.value = createCanvas(treeNode, canvasElement, options);
};

watch(
  () => layoutStore.currentNode,
  () => {
    generateNodeCanvas();
  }
);

const generateNodeCanvas = () => {
  if (!currentRoomgroup.value) return;
  roomsOfRoomgroup.value = roomStore.getRoomsByRoomGroupId(
    currentRoomgroup.value.id
  );
  firstRoom.value = roomStore.getFirstRoomOfRoomgroup(
    currentRoomgroup.value.id
  );

  const treeNode = createTreeNode(
    firstRoom.value,
    roomsOfRoomgroup.value,
    getCurrentNode.value.id
  );
  canvas.value?.redraw(
    treeNode,
    isSwapModeActive.value,
    roomGroupFinishedClicked.value
  );
};
</script>

<style scoped lang="scss">
.canvas-bg-dark {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.03) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
  background-size: 20px 20px;
}

.canvas-bg-light {
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}
</style>
