<template>
  <div class="flex flex-col gap-6">
    <BaseInformation
      v-if="infoText"
      status="info"
      :information-text="infoText"
    />

    <div v-if="radiators.length > 0" class="flex justify-between">
      <div class="self-center text-xl font-bold">Erfasste Heizkörper</div>
      <TertiaryButton
        label="Reihenfolge anpassen"
        icon="mdi-swap-horizontal"
        @click="toggleSwapMode"
      />
    </div>

    <div data-testid="radiatorCardList" class="flex flex-col gap-6">
      <RadiatorCard
        v-for="(radiator, key) in radiators"
        :key="key"
        :radiator="radiator"
        :parent-id="parentId"
        :is-swap-mode-active="isSwapModeActive"
      />
    </div>
    <div class="fixed bottom-8 right-10 z-10 flex justify-between">
      <ScrollButton
        button-text="Heizkörper erfassen"
        button-icon="heating-coil"
        data-testid="addRadiatorButton"
        @on-click="goToAddRadiator"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { entityTypes } from '@/enums/generic';
import { deviceTypes } from '@/enums/device';
import RadiatorCard from '@/components/Forms/RadiatorListFormComponents/RadiatorCard.vue';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFormStore } from '@/store/form/formStore';
import { Form } from '@/models/Form';
import TertiaryButton from '@/components/UI/Button/TertiaryButton.vue';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';
import { useRoomStore } from '@/store/entities/roomStore';
import { ref, computed } from 'vue';

const props = defineProps<{
  parentId: string;
}>();

const radiatorStore = useRadiatorStore();
const entitiesStore = useEntitiesStore();
const formStore = useFormStore();

const isSwapModeActive = ref(false);

const radiators = computed(() => {
  const radiators = radiatorStore.getAllRadiatorsInRoom(props.parentId);
  if (!radiators) {
    throw new Error("Can't call this component on a non Room node");
  }
  radiators.sort((a, b) => a.ordinal - b.ordinal);
  return radiators;
});

const currentRoom = computed(() => {
  return useRoomStore().getRoomById(props.parentId);
});

const infoText = computed(() => {
  if (currentRoom.value.hasRadiator === false) {
    return 'In diesem Raum sind keine Heizkörper vorhanden.';
  }
  if (radiators.value.length === 0) {
    return 'In diesem Raum sind noch keine Heizkörper installiert.';
  }

  return undefined;
});

const goToAddRadiator = () => {
  formStore.createEntity(
    new Form()
      .setParentId(props.parentId)
      .setName(entityTypes.radiator)
      .setPayload({ deviceType: deviceTypes.HeatCostAllocator })
  );
};

const toggleSwapMode = () => {
  isSwapModeActive.value = !isSwapModeActive.value;
};
</script>

<style lang="scss" scoped>
.button.is-big {
  width: 360px;
}

.description {
  max-width: 1000px;
}
</style>
