<template>
  <tr class="border-b bg-light-3 dark:border-dark-5 dark:bg-dark-3">
    <td class="rounded-bl-xl px-6 py-4">
      {{ installationPointType }}
    </td>
    <td class="px-6 py-4">
      {{ installationPoint.failedInstallationAttempt.reason }}
    </td>
    <td class="px-6 py-4"></td>
    <td class="rounded-br-xl px-6 py-4">
      <OptionsDropdown
        icon="mdi-dots-vertical"
        direction-x="left"
        direction="down"
      >
        <li
          data-testid="exchangeDeviceButton"
          @click="onExchange(installationPoint)"
        >
          <em class="mdi mdi-swap-horizontal mr-2" />Montieren
        </li>
        <li data-testid="editBuildingButton" @click="onEdit(installationPoint)">
          <em class="mdi mdi-pencil mr-2" />Bearbeiten
        </li>
        <li
          v-if="showDelete"
          data-testid="deleteBuildingButton"
          @click="onDelete(installationPoint)"
        >
          <em class="mdi mdi-delete mr-2" />Löschen
        </li>
      </OptionsDropdown>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { deviceTypeDetails } from '@/enums/device';
import { computed } from 'vue';
import OptionsDropdown from '@/components/UI/Dropdown/OptionsDropdown.vue';
import { DeviceType } from '@/models/devices/Device';
import { useOrderStore } from '@/store/order/orderStore';
import { EntityTransactionType } from '@/models/OrderChanges';

const installationPointType = computed(() => {
  return deviceTypeDetails[
    props.installationPoint?.supportedDeviceType as DeviceType
  ].shortLabel;
});

const props = defineProps({
  installationPoint: {
    type: InstallationPoint,
    required: true,
  },
  onEdit: {
    type: Function,
    required: true,
  },
  onDelete: {
    type: Function,
    required: true,
  },
  onExchange: {
    type: Function,
    required: true,
  },
});

const showDelete = computed(() =>
  useOrderStore().hasTransaction(
    props.installationPoint?.id,
    EntityTransactionType.CREATE_ENTITY
  )
);
</script>
