import { Pinia, Store } from '@/store/pinia-class-component';
import {
  deleteImages,
  fetchImages,
  fetchImagesConfirmation,
} from '@/api/pictures/PicturesApi';
import { ImageObjectType } from '@/models/Image';
import { PictureConfirmation } from '@/api/pictures/PictureConfirmation';
import { Image } from '@/models/Image';
import * as indexedDB from '@/utils/indexedDb/IndexedDb';
import { deleteEntityByIdIndex } from '@/utils/indexedDb/IndexedDb';
import { Notification } from '@/models';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';

export function useImagesStore() {
  return new ImageStore();
}

type ImageUpload = {
  currentUploadedImage: number;
  totalNumberOfImages: number;
  isImageUploadActive: boolean;
  failedImages: Image[];
};
@Store
export class ImageStore extends Pinia {
  private readonly _pictureConfirmations: PictureConfirmation[];
  private _localImages: Image[];
  private _modifiedImages: Image[];
  private _image: Image | undefined;
  private _isImageOpen: boolean;
  private _remoteImages: Image[];
  private _imagesToBeDeleted: Image[];
  private _keywordLists: { [key: string]: Set<string> };
  private _imageUpload: ImageUpload;

  constructor() {
    super();
    this._pictureConfirmations = [];
    this._image = undefined;
    this._localImages = [];
    this._modifiedImages = [];
    this._isImageOpen = false;
    this._remoteImages = [];
    this._imagesToBeDeleted = [];
    this._keywordLists = {
      BUILDING: new Set(),
      INSPECTED_BUILDING: new Set(),
      ORDER: new Set(),
    };
    this._imageUpload = {
      currentUploadedImage: 0,
      totalNumberOfImages: 0,
      isImageUploadActive: false,
      failedImages: [],
    };
  }

  get pictureConfirmations(): PictureConfirmation[] {
    return this._pictureConfirmations;
  }

  get localImages(): Image[] {
    return this._localImages;
  }

  get remoteImages(): Image[] {
    return this._remoteImages;
  }

  get modifiedImages(): Image[] {
    return this._modifiedImages;
  }

  get imagesToBeDeleted(): Image[] {
    return this._imagesToBeDeleted;
  }

  get image(): Image | undefined {
    return this._image;
  }

  get isImageOpen(): boolean {
    return this._isImageOpen;
  }

  get imageUpload(): ImageUpload {
    return this._imageUpload;
  }

  async getLocalImages() {
    this._localImages = await indexedDB.getEntitiesFromDBByIndex(
      useEntitiesStore().activeBusinessEntityId,
      undefined,
      'businessEntityId'
    );
  }

  updateModifiedImages() {
    this._modifiedImages = this.findModifiedImages(
      this.remoteImages,
      this.localImages
    );
  }

  openImage() {
    this._isImageOpen = true;
  }

  setImage(image: Image) {
    this._image = image;
  }

  resetImage() {
    this._image = undefined;
  }

  closeImage() {
    this._isImageOpen = false;
    this.resetImage();
  }

  getKeywordList(type: ImageObjectType | undefined | null): Set<string> {
    if (!type) return new Set();

    return this._keywordLists[type] || new Set();
  }
  updateKeywordList(
    type: ImageObjectType | undefined | null,
    keywords: string[]
  ) {
    if (!type) return new Set();

    keywords.forEach((keyword) => {
      this._keywordLists[type].add(keyword);
    });
  }

  objectHasImages(objectId: string): boolean {
    return this._pictureConfirmations.some((img) => img.objectId === objectId);
  }

  addImageToRemote(image: Image) {
    this._remoteImages.push(image);
  }

  addImagesToBeDeleted(images: Image[]) {
    images.forEach((image) => this._imagesToBeDeleted.push(image));
  }

  resetImagesToBeDeleted() {
    this._imagesToBeDeleted = [];
  }

  addObjectToList(objectId: string, objectType: ImageObjectType) {
    if (!this.objectHasImages(objectId)) {
      this._pictureConfirmations.push(
        new PictureConfirmation(objectId, objectType)
      );
    }
  }

  async getImagesConfirmation(businessEntityId: string) {
    const picturesConfirmation = await fetchImagesConfirmation(
      businessEntityId
    );
    picturesConfirmation.forEach(({ objectId, objectType }) => {
      if (!this.objectHasImages(objectId)) {
        this.addObjectToList(objectId, objectType);
      }
    });
  }

  getImages(businessEntityId: string, objectId: string) {
    return fetchImages(businessEntityId, objectId);
  }

  async deleteImagesFromRemote() {
    if (!useEntitiesStore().activeBusinessEntityId) return;

    const imagesToBeDeleted = this.imagesToBeDeleted;

    if (imagesToBeDeleted.length > 0) {
      const businessEntityId = imagesToBeDeleted[0].businessEntityId;
      await deleteImages(businessEntityId, imagesToBeDeleted).then(() => {
        this.resetImagesToBeDeleted();
      });
    }
  }

  isImageDetailsModified = (
    remoteImage: Image | undefined,
    image: Image,
    propertyListPass: (keyof Image)[] = []
  ): boolean => {
    if (!remoteImage) {
      return true;
    }

    return Object.entries(image).some(([key, value]) => {
      if (propertyListPass.includes(key as keyof Image)) {
        return false;
      }
      if (typeof value === 'object') {
        return (
          JSON.stringify(value) !==
          JSON.stringify(remoteImage[key as keyof Image])
        );
      }
      return value !== remoteImage[key as keyof Image];
    });
  };

  findModifiedImages = (remoteImages: Image[], images: Image[]) => {
    const modifiedImages: Image[] = [];

    const remoteImagesMap = new Map(
      remoteImages.map((item) => [item.id, item])
    );

    images.forEach((image) => {
      const remoteImage = remoteImagesMap.get(image.id);
      if (this.isImageDetailsModified(remoteImage, image)) {
        modifiedImages.push(image);
      }
    });

    return modifiedImages;
  };

  async uploadImages() {
    if (!useEntitiesStore().activeBusinessEntityId) return;
    await this.getLocalImages();
    this.updateModifiedImages();

    this._imageUpload.totalNumberOfImages = this.modifiedImages.length;
    this._imageUpload.isImageUploadActive = true;

    try {
      for (const image of this.modifiedImages) {
        const response = await usePersistenceStore().saveImage(image);

        // No internet connection or Server Unreachable
        if (!response || response.status >= 400) {
          (this._imageUpload.failedImages as Image[]).push(image);
          return;
        }

        if (!image.id) return;
        if (response.status < 400) {
          this._imageUpload.currentUploadedImage++;
          await deleteEntityByIdIndex(image.id);
        }
      }

      await this.deleteImagesFromRemote();

      if (this._imageUpload.failedImages.length === 0) {
        await indexedDB.clearObjectStore();
      } else {
        useNotificationStore().addNotification(
          new Notification(
            'Keine Internet-Verbindung oder Server unerreichbar',
            'Bildspeicherung fehlgeschlagen',
            'error',
            8000
          )
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        this._imageUpload.isImageUploadActive = false;
        this._imageUpload.currentUploadedImage = 0;
      }, 3000);
    }
  }
}
